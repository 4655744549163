import { ExclamationCircleIcon, InformationCircleIcon } from '@heroicons/react/20/solid';
import { useState } from 'react';

import { Tooltip } from '../Tooltip';
import './Inputs.scss';

interface TextValueInputProps {
  name: string;
  title: string;
  type?: string;
  textValue?: string;
  placeholder?: string;
  tooltipActive?: boolean;
  tooltipMessage?: string;
  tooltipRight?: boolean;
  inputValueInvalide?: boolean;
  invalideMessage?: string;
  required?: boolean;
  setTextValue?: (name: string) => void;
}

export function TextValueInput(props: TextValueInputProps): JSX.Element {
  const [showInfoTooltip, setShowInfoTooltip] = useState(false);
  const [showRequiredTooltip, setShowRequiredTooltip] = useState(false);

  const inputType = props.type ? props.type : 'text';
  return (
    <div className="custom-input">
      <div className="flex">
        {props.tooltipActive && (
          <Tooltip
            message={<p>{props.tooltipMessage}</p>}
            show={showInfoTooltip}
            setShow={setShowInfoTooltip}
            rightAligned={props.tooltipRight}
          >
            <InformationCircleIcon
              className="mr-0.5 h-5 w-5 text-blue-800 hover:text-blue-900 hover:cursor-pointer"
              title="ClaimDescription"
            />
          </Tooltip>
        )}
        {props.required && (
          <Tooltip
            message={<p>{'Required!'}</p>}
            show={showRequiredTooltip}
            setShow={setShowRequiredTooltip}
            rightAligned={true}
          >
            <ExclamationCircleIcon
              className="mr-0.5 mt-0.5 h-4 w-4 text-amber-500 hover:text-amber-600 hover:cursor-pointer"
              title="requiredIcon"
            />
          </Tooltip>
        )}
        <label htmlFor="name" className="text-input-label">
          {props.title}
        </label>
        {props.inputValueInvalide && (
          <div className=" block text-sm font-medium ml-2 -translate-y-0.5 px-2 shadow-sm rounded-md border border-5minds-blue-900 bg-amber-500">
            {props.invalideMessage}
          </div>
        )}
      </div>
      <div className="mt-1">
        <input
          type={inputType}
          name={props.name}
          className={'text-input'}
          defaultValue={props.textValue}
          placeholder={props.placeholder}
          onChange={(e) => props.setTextValue?.(e.target.value)}
        />
      </div>
    </div>
  );
}
