import { Disclosure, Menu, Transition } from '@headlessui/react';
import { UserIcon } from '@heroicons/react/20/solid';
import { ArrowRightOnRectangleIcon, Bars3Icon, UserPlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import React, { Fragment, PropsWithChildren, useEffect, useState } from 'react';

const DASHBOARD_ENDPOINT = '/admin';
const SCOPES_ENDPOINT = '/admin/scopes';
const CLAIMS_ENDPOINT = '/admin/claims';
const GROUPS_ENDPOINT = '/admin/groups';
const LOGOUT_ENDPOINT = '/session/end';

type MenuItem = {
  name: string;
  href: string;
  current: boolean;
  icon?: JSX.Element;
};

type NavBarProps = {
  logo: string;
  routerPrefix: string;
  issuerUrl?: string;
  leftMenuItems?: MenuItem[];
  rightMenuItems?: MenuItem[];
};

function setStatefulIdToken(routerPrefix: string, setIdToken: React.Dispatch<React.SetStateAction<string>>): void {
  useEffect(() => {
    fetch(`${routerPrefix}/get_id_token_cookie`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(async (response) => setIdToken(await response.json()));
  }, []);
}

export function WithDefaultNavBar(props: PropsWithChildren<NavBarProps>): JSX.Element {
  const [idToken, setIdToken] = useState('');

  setStatefulIdToken(props.routerPrefix, setIdToken);

  const postLogoutRedirectUri = new URL('/acr/username_password/signout-oidc', props.issuerUrl);
  const logoutUrlWithParams = new URL(LOGOUT_ENDPOINT, props.issuerUrl);
  logoutUrlWithParams.searchParams.set('id_token_hint', idToken);
  logoutUrlWithParams.searchParams.set('post_logout_redirect_uri', postLogoutRedirectUri.toString());

  const invalidIdToken = !idToken.startsWith('ey');

  const leftMenuItems = [
    {
      name: 'Dashboard',
      href: `${props.routerPrefix}${DASHBOARD_ENDPOINT}`,
      current: window.location.pathname.endsWith(DASHBOARD_ENDPOINT),
    },
    {
      name: 'Groups',
      href: `${props.routerPrefix}${GROUPS_ENDPOINT}`,
      current: window.location.pathname.endsWith(GROUPS_ENDPOINT),
    },
    {
      name: 'Scopes',
      href: `${props.routerPrefix}${SCOPES_ENDPOINT}`,
      current: window.location.pathname.endsWith(SCOPES_ENDPOINT),
    },
    {
      name: 'Claims',
      href: `${props.routerPrefix}${CLAIMS_ENDPOINT}`,
      current: window.location.pathname.endsWith(CLAIMS_ENDPOINT),
    },
  ];
  const rightMenuItems = invalidIdToken
    ? []
    : [
        {
          name: 'Logout',
          href: logoutUrlWithParams.toString(),
          icon: <ArrowRightOnRectangleIcon tabIndex={-1} className="-ml-0.5 h-5 w-5 z-[999]" aria-hidden="true" />,
          current: false,
        },
      ];

  return (
    <WithNavBar
      logo={props.logo}
      routerPrefix={props.routerPrefix}
      leftMenuItems={leftMenuItems}
      rightMenuItems={rightMenuItems}
    >
      {props.children}
    </WithNavBar>
  );
}

export function WithNavBar(props: PropsWithChildren<NavBarProps>): JSX.Element {
  return (
    <div className="flex flex-col w-screen h-screen max-h-screen">
      <NavBar
        logo={props.logo}
        routerPrefix={props.routerPrefix}
        leftMenuItems={props.leftMenuItems}
        rightMenuItems={props.rightMenuItems}
      />
      <div className="h-full overflow-auto">{props.children}</div>
    </div>
  );
}

export function NavBar(props: NavBarProps): JSX.Element {
  const leftMenuItems = props.leftMenuItems ?? [];
  const rightMenuItems = props.rightMenuItems ?? [];

  return (
    <Disclosure as="nav" tabIndex={-1} className="bg-white shadow w-screen">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 justify-between">
              <div className="flex">
                <div className="-ml-2 mr-2 flex items-center md:hidden">
                  <MobileMenuButton open={open} />
                </div>
                <div className="flex flex-shrink-0 items-center">
                  <a
                    className="focus:outline-none focus:ring-2 focus:ring-5minds-blue-900 focus:ring-offset-2"
                    href={`${props.routerPrefix}${DASHBOARD_ENDPOINT}`}
                  >
                    <img className="block h-8 w-auto lg:hidden" src={props.logo} />
                    <img className="hidden h-8 w-auto lg:block" src={props.logo} />
                  </a>
                </div>
                <div className="hidden md:ml-6 md:flex md:space-x-8">
                  <TabItems menuItems={leftMenuItems} />
                </div>
              </div>
              <div className="flex items-center">
                <div className="hidden md:ml-4 md:flex md:flex-shrink-0 md:items-center">
                  <MenuItems menuItems={rightMenuItems} />
                </div>
              </div>
            </div>
          </div>
          <MobileMenu leftMenuItems={leftMenuItems} rightMenuItems={rightMenuItems} />
        </>
      )}
    </Disclosure>
  );
}

function TabItems(props: { menuItems: MenuItem[] }): JSX.Element {
  return (
    <>
      {props.menuItems.map((item) => (
        <a
          key={item.name}
          href={item.href}
          className={`inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-5minds-blue-900 ${
            item.current
              ? 'border-5minds-blue-700 text-gray-900'
              : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
          }`}
        >
          {item.name}
        </a>
      ))}
    </>
  );
}

function MenuItems(props: { menuItems: MenuItem[] }): JSX.Element {
  return (
    <Menu as="div" className="relative ml-3">
      <div>
        <Menu.Button
          name="logoutmenubutton"
          className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-5minds-blue-900 focus:ring-offset-2"
        >
          <span className="sr-only">Open user menu</span>
          <UserIcon className="h-10 w-10 rounded-full text-gray-500" />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {props.menuItems.map((item) => (
            <Menu.Item key={item.name}>
              {({ active }) => (
                <a
                  href={item.href}
                  className={`flex gap-2 px-4 py-2 text-sm text-gray-700 ${active ? 'bg-gray-100' : ''}`}
                >
                  {item.icon}
                  {item.name}
                </a>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

function MobileTabItems(props: { menuItems: MenuItem[] }): JSX.Element {
  return (
    <>
      {props.menuItems.map((item) => (
        <Disclosure.Button
          as="a"
          href={item.href}
          className={`block border-l-4 py-2 pl-3 pr-4 text-base font-medium sm:pl-5 sm:pr-6 ${
            item.current
              ? 'bg-5minds-blue-100 border-5minds-blue-900 text-5minds-blue-900'
              : 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700'
          }`}
        >
          {item.name}
        </Disclosure.Button>
      ))}
    </>
  );
}

function MobileMenuButton(props: { open: boolean }): JSX.Element {
  return (
    <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-5minds-blue-900">
      <span className="sr-only">Open main menu</span>
      {props.open ? (
        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
      ) : (
        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
      )}
    </Disclosure.Button>
  );
}

function MobileMenuItems(props: { menuItems: MenuItem[] }): JSX.Element {
  return (
    <>
      {props.menuItems.map((item) => (
        <Disclosure.Button
          as="a"
          href={item.href}
          className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 sm:px-6"
        >
          {item.name}
        </Disclosure.Button>
      ))}
    </>
  );
}

function MobileMenu(props: { leftMenuItems: MenuItem[]; rightMenuItems: MenuItem[] }): JSX.Element {
  return (
    <Disclosure.Panel className="md:hidden">
      <div className="space-y-1 pt-2 pb-3">
        <MobileTabItems menuItems={props.leftMenuItems} />
      </div>
      <div className="border-t border-gray-200 pt-4 pb-3">
        <div className="flex items-center px-4 sm:px-6">
          <div className="flex-shrink-0">
            <UserIcon className="h-8 w-8 rounded-full text-gray-500" />
          </div>
        </div>
        <div className="mt-3 space-y-1">
          <MobileMenuItems menuItems={props.rightMenuItems} />
        </div>
      </div>
    </Disclosure.Panel>
  );
}
