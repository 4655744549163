import debounce from 'lodash.debounce';
import cloneDeep from 'lodash/cloneDeep';
import { useCallback, useEffect, useState } from 'react';

import { Claim, ClaimTypes, Group, Scope, User, UserClaim } from '@5minds/processcube_authority_sdk';

import { ErrorNotification, Tab, WithDefaultNavBar, WithTabs } from '../../../components';
import { FrontendGroup, FrontendScope, FrontendUser, FrontendUserClaim } from '../../../contracts';
import {
  filterScopes,
  filterUserClaims,
  parseUserClaims,
  parseUserGroups,
  parseUserScopes,
  sortByName,
} from '../../../infrastructure';
import { EditUserAccount } from './EditUserAccount';
import { EditUserGroups } from './EditUserGroups';
import { EditUserPermissions } from './EditUserPermissions';

type EditUserPageProps = {
  routerPrefix: string;
  logo: string;
  issuerUrl: string;
  user: User;
  userGroupNames: string[];
  groups: Group[];
  scopes: Scope[];
  claims: Claim[];
  scopelessClaims: Claim[];
};

export function EditUserPage(props: EditUserPageProps): JSX.Element {
  const [tabs, setTabs] = useState<Tab[]>([
    {
      name: 'Account',
      href: 'account',
      current:
        new URLSearchParams(window.location.search).get('tab') === 'account' ||
        new URLSearchParams(window.location.search).get('tab') == null,
    },
    {
      name: 'Permissions',
      href: 'permissions',
      current: new URLSearchParams(window.location.search).get('tab') === 'permissions',
    },
    {
      name: 'Groups',
      href: 'groups',
      current: new URLSearchParams(window.location.search).get('tab') === 'groups',
    },
  ]);

  const parsedGroups: FrontendGroup[] = parseUserGroups(props.groups, props.userGroupNames);
  const parsedScopes: FrontendScope[] = parseUserScopes(
    props.scopes.sort(sortByName),
    props.user.scopesFromGroups,
    props.user,
  );
  const parsedClaims: FrontendUserClaim[] = parseUserClaims(
    [...props.claims, ...props.scopelessClaims],
    props.user.claims,
  );

  const [groups, setGroups] = useState<FrontendGroup[]>(parsedGroups);
  const [scopes, setScopes] = useState<FrontendScope[]>(parsedScopes);
  const [claims, setClaims] = useState<FrontendUserClaim[]>(parsedClaims);

  const [filteredScopes, setFilteredScopes] = useState<FrontendScope[]>(scopes);
  const [filteredClaims, setFilteredClaims] = useState<FrontendUserClaim[]>(claims);
  const [search, setSearch] = useState<string>('');

  const [user, setUser] = useState<FrontendUser>({ ...props.user, password: '' });
  const [error, setError] = useState<string | null>(null);

  let usernameForUpdateClaim = user.username;

  function updateUserData(user: User) {
    const parsedScopes: FrontendScope[] = parseUserScopes(props.scopes.sort(sortByName), user.scopesFromGroups, user);
    const parsedClaims: FrontendUserClaim[] = parseUserClaims([...props.claims, ...props.scopelessClaims], user.claims);

    setScopes(parsedScopes);
    setClaims(parsedClaims);
  }

  async function updateUserDetails(name: string, password: string, changedOidcClaims: Array<UserClaim>) {
    const nameOrPasswordChanged = user.username !== name || password !== '';
    const oidcClaimsChanged = changedOidcClaims.length !== 0;

    if (nameOrPasswordChanged || oidcClaimsChanged) {
      const currentUnixTimestamp = Date.now();
      changedOidcClaims.push({
        id: claims.find((claim) => claim.name === 'updated_at')!.id,
        name: 'updated_at',
        value: currentUnixTimestamp,
        type: ClaimTypes.INT,
      });
    }

    if (nameOrPasswordChanged) {
      try {
        await changeUserameAndPassword(name, password);
      } catch {
        return;
      }
      usernameForUpdateClaim = name;

      const updatedClaimsFromUser = await changeOidcClaimsFromUser(changedOidcClaims);
      const claimsWereUpdated = checkIfClaimsWereUpdated(updatedClaimsFromUser);
      if (claimsWereUpdated) {
        setUser({
          ...user,
          username: name,
          claims: updatedClaimsFromUser,
        });
      } else {
        setUser({
          ...user,
          username: name,
        });
      }
      return;
    }

    if (oidcClaimsChanged) {
      const updatedClaimsFromUser = await changeOidcClaimsFromUser(changedOidcClaims);
      const claimsWereUpdated = checkIfClaimsWereUpdated(updatedClaimsFromUser);
      if (claimsWereUpdated) {
        setUser({
          ...user,
          claims: updatedClaimsFromUser,
        });
      }
    }
  }

  async function changeUserameAndPassword(name: string, password: string): Promise<void> {
    const body = {
      name,
      password,
    };

    await fetch(`${props.routerPrefix}/admin/user/${user.username}/update`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }).then(async (response) => {
      if (!response.ok) {
        const error = await response.json();

        setError(`${error.additionalInformation.name}: ${error.message}`);
        return;
      }
      return response.json();
    });
  }

  async function changeOidcClaimsFromUser(changedOidcClaims: Array<UserClaim>): Promise<Array<UserClaim>> {
    const updatedClaimsFromUser = cloneDeep(user.claims);

    await Promise.all(
      changedOidcClaims.map(async (changedOidcClaim) => {
        try {
          await changeClaim(changedOidcClaim.name, changedOidcClaim.value);
        } catch {
          return;
        }
        updatedClaimsFromUser.find((userClaims) => userClaims.name === changedOidcClaim.name)!.value =
          changedOidcClaim.value;
      }),
    );

    return updatedClaimsFromUser;
  }

  function checkIfClaimsWereUpdated(updatedClaimsFromUser: UserClaim[]): boolean {
    const valuesFromUserClaims = user.claims.map((claim) => claim.value);
    const valuesFromUpdatedClaims = updatedClaimsFromUser.map((claim) => claim.value);
    const claimsWereUpdated = JSON.stringify(valuesFromUserClaims) !== JSON.stringify(valuesFromUpdatedClaims);

    return claimsWereUpdated;
  }

  function addUserToGroup(group: FrontendGroup, callback?: (user: User) => void) {
    fetch(`${props.routerPrefix}/admin/user/${user.username}/add/group`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ groupName: group.name }),
    }).then(async (response) => {
      if (!response.ok) {
        const error = await response.json();

        setError(`${error.additionalInformation.name}: ${error.message}`);
        return;
      }

      const updatedUser = await response.json();

      updateUserData(updatedUser);
      if (callback) {
        callback(updatedUser);
      }
    });
  }

  function removeUserFromGroup(group: FrontendGroup, callback?: (user: User) => void) {
    fetch(`${props.routerPrefix}/admin/user/${user.username}/remove/group`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ groupName: group.name }),
    }).then(async (response) => {
      if (!response.ok) {
        const error = await response.json();

        setError(`${error.additionalInformation.name}: ${error.message}`);
        return;
      }

      const updatedUser = await response.json();

      updateUserData(updatedUser);
      if (callback) {
        callback(updatedUser);
      }
    });
  }

  function addUserScope(scope: FrontendScope, callback?: (userClaims: UserClaim[]) => void) {
    fetch(`${props.routerPrefix}/admin/user/${user.username}/add/scope`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ scopeName: scope.name }),
    }).then(async (response) => {
      if (!response.ok) {
        const error = await response.json();

        setError(`${error.additionalInformation.name}: ${error.message}`);
        return;
      }

      const updatedClaims = await response.json();

      if (callback) {
        callback(updatedClaims);
      }
    });
  }

  function removeUserScope(scope: FrontendScope, callback?: (userClaims: UserClaim[]) => void) {
    fetch(`${props.routerPrefix}/admin/user/${user.username}/remove/scope`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ scopeName: scope.name }),
    }).then(async (response) => {
      if (!response.ok) {
        const error = await response.json();

        setError(`${error.additionalInformation.name}: ${error.message}`);
        return;
      }

      const updatedClaims = await response.json();

      if (callback) {
        callback(updatedClaims);
      }
    });
  }

  function toggleScope(name: string) {
    const scope = scopes.find((s) => s.name === name);

    if (!scope) return;

    scope.enabled = !scope.enabled;

    if (scope.enabled) {
      addUserScope(scope, (userClaims: UserClaim[]) => {
        setScopes([...scopes]);
        setClaims(parseUserClaims([...props.claims, ...props.scopelessClaims], userClaims));
      });
    } else {
      removeUserScope(scope, (userClaims: UserClaim[]) => {
        setScopes([...scopes]);
        setClaims(parseUserClaims([...props.claims, ...props.scopelessClaims], userClaims));
      });
    }
  }

  function toggleClaim(name: string) {
    const claim = claims.find((c) => c.name === name);
    if (claim) {
      claim.enabled = !claim.enabled;
      setClaims([...claims]);
    }
  }

  async function changeClaim(name: string, value: any) {
    const claim = claims.find((c) => c.name === name);
    if (!claim) return;

    await fetch(`${props.routerPrefix}/admin/user/${usernameForUpdateClaim}/update/claim`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ claimName: claim.name, claimValue: value }),
    })
      .then(async (response) => {
        if (!response.ok) {
          const error = await response.json();

          setError(`${error.additionalInformation.name}: ${error.message}`);
          return;
        }
      })
      .then(() => {
        claim.value = value;
        setClaims([...claims]);
      });
  }

  function getEnabledScopesByClaim(claim: FrontendUserClaim): FrontendScope[] {
    return scopes.filter((scope) => scope.enabled && scope.claims.includes(claim.name));
  }

  function filterScopesAndClaims(filterTerm: string) {
    const filteredClaims = filterUserClaims(filterTerm, claims);
    const includedClaims = filteredClaims.map((c) => c.name.toLowerCase());
    const filteredScopes = filterScopes(filterTerm, scopes, includedClaims);
    setFilteredScopes(filteredScopes);
    setFilteredClaims(filteredClaims);
  }

  function renderTabContent(search: URLSearchParams) {
    const tab = search.get('tab');
    switch (tab) {
      case 'groups':
        return (
          <EditUserGroups groups={groups} addUserToGroup={addUserToGroup} removeUserFromGroup={removeUserFromGroup} />
        );
      case 'permissions':
        return (
          <EditUserPermissions
            claims={filteredClaims}
            scopes={filteredScopes}
            changeClaim={changeClaim}
            getEnabledScopesByClaim={getEnabledScopesByClaim}
            setSearch={setSearch}
            toggleClaim={toggleClaim}
            toggleScope={toggleScope}
          />
        );
      default:
        return <EditUserAccount routerPrefix={props.routerPrefix} user={user} updateUserDetails={updateUserDetails} />;
    }
  }

  const debouncedFilter = useCallback(
    debounce((search: string) => {
      filterScopesAndClaims(search);
    }, 250),
    [],
  );

  useEffect(() => {
    filterScopesAndClaims(search);
  }, [scopes, claims]);

  useEffect(() => {
    debouncedFilter(search);
  }, [search]);

  return (
    <>
      <ErrorNotification message={error} setMessage={setError} />
      <WithDefaultNavBar issuerUrl={props.issuerUrl} logo={props.logo} routerPrefix={props.routerPrefix}>
        <WithTabs tabs={tabs} setTabs={setTabs}>
          {renderTabContent(new URLSearchParams(window.location.search))}
        </WithTabs>
      </WithDefaultNavBar>
    </>
  );
}
