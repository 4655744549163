import { Dialog } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import React from 'react';

import { Scope } from '@5minds/processcube_authority_sdk';

import { Modal } from '../../../components';

type DeleteScopeDialogProps = {
  scope: Scope;
  show: boolean;
  hide: () => void;
  deleteScope: (scopeName: string) => void;
};

export function DeleteScopeDialog(props: DeleteScopeDialogProps): JSX.Element {
  const submit = () => {
    props.deleteScope(props.scope.name);
  };

  return (
    <Modal show={props.show} hide={props.hide}>
      <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <Dialog.Title as="h3" className="text-lg font-semibold leading-6 text-gray-900">
              Delete "{props.scope.name}"?
            </Dialog.Title>
            <div className="flex flex-col mt-2 gap-2">
              <p className="text-sm font-semibold text-gray-700">
                This scope is currently referenced by{' '}
                {props.scope.claims.length > 0 ? `${props.scope.claims.length} claims:` : `no claims.`}
                {props.scope.claims.map((c) => c.name).join(', ')}
              </p>
              <p className="text-sm text-gray-700">
                Are you sure you want to delete this scope? All related data will be permanently removed. This action
                cannot be undone.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-700 sm:ml-3 sm:w-auto outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600"
          onClick={submit}
        >
          Delete
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto outline-none focus:ring-2 focus:ring-offset-2 focus:ring-5minds-blue-900"
          onClick={props.hide}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
}
