import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { useState } from 'react';

import { Tooltip } from '../Tooltip';

type PasswordInputProps = {
  password?: string;
  userCreation?: boolean;
  setPassword?: (password: string) => void;
  title?: string;
  name?: string;
};

export function PasswordInput(props: PasswordInputProps): JSX.Element {
  const [showRequiredTooltip, setRequiredTooltip] = useState(false);

  return (
    <div className="custom-input">
      <div className="flex">
        {props.userCreation && (
          <Tooltip
            message={<p>{'Required!'}</p>}
            show={showRequiredTooltip}
            setShow={setRequiredTooltip}
            rightAligned={true}
          >
            <ExclamationCircleIcon
              className="mr-0.5 mt-0.5 h-4 w-4 text-amber-500 hover:text-amber-600 hover:cursor-pointer"
              title="requiredIcon"
            />
          </Tooltip>
        )}
        <label htmlFor="password" className="text-input-label">
          {props.title ?? 'Password'}
        </label>
      </div>
      <div className="mt-1">
        <input
          type="password"
          name={props.name ?? 'password'}
          className="text-input"
          defaultValue={props.password}
          onChange={(e) => props.setPassword?.(e.target.value)}
        />
      </div>
    </div>
  );
}
