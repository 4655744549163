import { Transition } from '@headlessui/react';
import React, { Fragment, PropsWithChildren } from 'react';

export const FadeInTransition = (): JSX.Element => {
  return (
    <Transition.Child
      as={Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="bg-gray-500/75 fixed inset-0 transition-opacity" />
    </Transition.Child>
  );
};

export const SlideTransition = (props: PropsWithChildren<{ show: boolean }>): JSX.Element => {
  return (
    <Transition
      show={props.show}
      as={Fragment}
      enter="transition ease-out duration-150"
      enterFrom="translate-y-1 opacity-0"
      enterTo="translate-y-0 opacity-100"
      leave="transition ease-in duration-150"
      leaveFrom="translate-y-0 opacity-100"
      leaveTo="translate-y-1 opacity-0"
    >
      {props.children}
    </Transition>
  );
};

export const ExpandTransition = (props: PropsWithChildren<{ show: boolean }>): JSX.Element => {
  return (
    <Transition
      as={Fragment}
      show={props.show}
      enter="transition ease-out duration-200"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      {props.children}
    </Transition>
  );
};
