import React from 'react';

import { FrontendGroup } from '../../../contracts';
import { UserGroupRenderer } from './UserGroupRenderer';

type UserGroupsRendererProps = {
  groups: FrontendGroup[];
  toggleGroup: (group: FrontendGroup) => void;
};

export function UserGroupsRenderer(props: UserGroupsRendererProps): JSX.Element {
  return (
    <div className="w-full h-full overflow-y-auto box-border p-4">
      <h2 className="mb-4 text-2xl font-bold leading-6 text-gray-900">Groups</h2>
      <div className="flex flex-col gap-2">
        {props.groups.map((group) => (
          <UserGroupRenderer key={group.id} group={group} toggleGroup={props.toggleGroup} />
        ))}
      </div>
    </div>
  );
}
