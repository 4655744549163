import React, { useState } from 'react';

import { NewUser } from '../../../contracts';

type AddUserConfirmProps = {
  routerPrefix: string;
  setError: (error: string | null) => void;
  user: NewUser;
};

export function AddUserConfirm(props: AddUserConfirmProps): JSX.Element {
  const claimValues: Record<string, any> = props.user.claims.reduce(
    (acc, claim) => {
      acc[claim.name] = claim.value;
      return acc;
    },
    {} as Record<string, any>,
  );

  if ('name' in claimValues) claimValues.name = props.user.username;

  const groupIds = props.user.groups.map((group) => group.id);
  const scopeIds = props.user.scopes.map((scope) => scope.id);

  const [summary] = useState({
    ...props.user,
    groups: props.user.groups.map((group) => group.name),
    scopes: props.user.scopes.map((scope) => scope.name),
    claims: props.user.claims.map((claim) => claim.name),
    password: props.user.password.replace(/./g, '*'),
    claimValues,
  });

  function submit() {
    const body = JSON.stringify({
      username: props.user.username,
      password: props.user.password,
      groupIds: groupIds,
      scopeIds: scopeIds,
      claimValues,
    });

    fetch(`${props.routerPrefix}/admin/user/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    }).then(async (response) => {
      if (!response.ok) {
        const error = await response.json();

        props.setError(`${error.additionalInformation.name}: ${error.message}`);
        return;
      }

      window.location.href = `${props.routerPrefix}/admin`;
    });
  }

  return (
    <div className="p-4 border h-full overflow-y-auto border-gray-200 border-t-transparent rounded-b-lg">
      <div className="h-full flex flex-col gap-3">
        <textarea
          className="w-full h-full border border-gray-200 bg-gray-50 font-mono resize-none"
          defaultValue={JSON.stringify(summary, null, 2)}
          readOnly
        />
        <button
          className="w-40 ml-auto inline-flex justify-center rounded-md border px-4 py-2 text-base font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm  focus:ring-5minds-blue-900 border-transparent text-white bg-5minds-blue-800 hover:bg-5minds-blue-900 sm:col-start-2"
          onClick={submit}
        >
          Submit
        </button>
      </div>
    </div>
  );
}
