import React from 'react';

import { FrontendClaim, FrontendScope } from '../../../contracts';
import { OIDC_CLAIM_NAMES } from '../../../infrastructure';
import { GroupClaimRenderer } from './GroupClaimRenderer';

type GroupClaimsRendererProps = {
  claims: FrontendClaim[];
  getEnabledScopesByClaim: (claim: FrontendClaim) => FrontendScope[];
};

export function GroupClaimsRenderer(props: GroupClaimsRendererProps): JSX.Element {
  const oidcClaimNames = OIDC_CLAIM_NAMES;
  const oidcClaims = props.claims.filter((claim) => oidcClaimNames.includes(claim.name));
  const customClaims = props.claims.filter((claim) => !oidcClaimNames.includes(claim.name));

  return (
    <div className="w-full h-full overflow-y-auto box-border p-4">
      <h2 className="mb-4 text-2xl font-bold leading-6 text-gray-900">Claims</h2>
      <div className="flex flex-col gap-2">
        {customClaims.map((claim) => (
          <GroupClaimRenderer
            key={claim.id}
            claim={claim}
            getEnabledScopesByClaim={props.getEnabledScopesByClaim}
            claimOriginForClaimId={'custom'}
          />
        ))}
      </div>
      <hr className="pb-4"></hr>
      <h2 className="mb-4 text-2xl font-bold leading-6 text-gray-900">OIDC-Claims</h2>
      <div className="flex flex-col gap-2 pb-4">
        {oidcClaims.map((claim) => (
          <GroupClaimRenderer
            key={claim.id}
            claim={claim}
            getEnabledScopesByClaim={props.getEnabledScopesByClaim}
            claimOriginForClaimId={'oidc'}
          />
        ))}
      </div>
    </div>
  );
}
