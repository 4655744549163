import React, { useState } from 'react';

import { User } from '@5minds/processcube_authority_sdk';

import { ErrorNotification, WithDefaultNavBar } from '../../../components';
import { DeleteUserDialog } from './DeleteUserDialog';
import { UserDetailsDialog } from './UserDetailsDialog';
import { UsersTable } from './UsersTable';

interface DashboardProps {
  routerPrefix: string;
  currentlyLoggedInAccountId: string;
  logo: string;
  issuerUrl: string;
  users?: Array<User>;
}

export function Dashboard(props: DashboardProps): JSX.Element {
  const currentlyLoggedInUser = props.users?.find((user) => user.accountId === props.currentlyLoggedInAccountId);
  const [users, setUsers] = useState<Array<User>>(
    props.users?.filter((user) => user.accountId !== currentlyLoggedInUser?.accountId) ?? [],
  );
  const [selectedUser, setSelectedUser] = useState<User>(currentlyLoggedInUser!);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [error, setError] = useState<string | null>(null);

  function deleteOrArchiveUser(username: string, archiveUser: boolean): void {
    fetch(`${props.routerPrefix}/admin/user/${username}/delete`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ archiveUser: archiveUser }),
    })
      .then(async (response) => {
        if (!response.ok) {
          const error = await response.json();

          setError(`${error.additionalInformation.name}: ${error.message}`);
          return;
        }

        const deletedUser = await response.json();

        if (archiveUser) {
          const updatedUser = users.find((user) => user.accountId === deletedUser.accountId);
          if (!updatedUser) {
            return;
          }
          updatedUser.isArchived = true;
          setUsers((users) => [...users.filter((user) => user.accountId !== deletedUser.accountId), updatedUser]);
          return;
        }
        setUsers(users.filter((user) => user.accountId !== deletedUser.accountId));
        setShowDeletePopup(false);
      })
      .catch((error) => {
        setError(error.message);
      });
  }

  function restoreArchivedUser(username: string): void {
    fetch(`${props.routerPrefix}/admin/user/${username}/archive/restore`, {
      method: 'PATCH',
    })
      .then(async (response) => {
        if (!response.ok) throw new Error(await response.text());
        return response.json();
      })
      .then((deletedUser: User) => {
        const updatedUser = users.find((user) => user.accountId === deletedUser.accountId);
        if (!updatedUser) {
          return;
        }
        updatedUser.isArchived = undefined;
        setUsers((users) => [...users.filter((user) => user.accountId !== deletedUser.accountId), updatedUser]);
      })
      .catch((error) => {
        setError(error.message);
      });
  }

  function deleteUser(username: string): void {
    const deleteUser = false;
    deleteOrArchiveUser(username, deleteUser);
  }

  function archiveUser(username: string): void {
    const archiveUser = true;
    deleteOrArchiveUser(username, archiveUser);
  }

  return (
    <>
      <ErrorNotification message={error} setMessage={setError} />

      <DeleteUserDialog
        routerPrefix={props.routerPrefix}
        user={selectedUser}
        show={showDeletePopup}
        deleteUser={deleteUser}
        hide={() => setShowDeletePopup(false)}
      />

      <WithDefaultNavBar issuerUrl={props.issuerUrl} logo={props.logo} routerPrefix={props.routerPrefix}>
        <UsersTable
          users={users}
          currentlyLoggedInUser={currentlyLoggedInUser!}
          routerPrefix={props.routerPrefix}
          setSelectedUser={setSelectedUser}
          setShowDeletePopup={setShowDeletePopup}
          archiveUser={archiveUser}
          restoreArchivedUser={restoreArchivedUser}
        />
      </WithDefaultNavBar>
    </>
  );
}
