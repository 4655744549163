import { CountryData, allCountries } from 'country-region-data';
import { useEffect, useState } from 'react';
import Select from 'react-select';

import { PasswordInput, TextValueInput, ToggleSwitch } from '../../../components';

type EditOidcClaimsProps = {
  username?: string;
  setUsername: (name: string) => void;
  password?: string;
  setPassword: (password: string) => void;
  claims: Array<any>;
  setClaims: (oidcClaims: Array<any>) => void;
  placeholderActive?: boolean;
  invalideCalims?: Array<Record<string, any>>;
  userCreation?: boolean;
};

const reactSelectStyling = {
  option: (provided: any, state: any) => ({
    ...provided,
    cursor: 'pointer',
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
    fontSize: '0.875rem',
    backgroundColor: state.isSelected ? '#696969' : state.isFocused ? '#808080' : '',
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    cursor: 'pointer',
    borderRadius: '0.375rem',
    fontSize: '0.875rem',
    borderColor: state.isFocused ? '#000000' : 'rgb(209 213 219)',
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid black',
    },
  }),
  input: (provided: any, state: any) => ({ ...provided, 'input:focus': { boxShadow: 'none' } }),
};

export function EditOidcClaims(props: EditOidcClaimsProps): JSX.Element {
  const [phoneNumber, setPhoneNumber] = useState<Record<string, string>>(() => {
    const fullPhoneNumber = props.claims.find((claim) => claim.name === 'phone_number').value;
    if (!fullPhoneNumber) return { prefix: '', phone_number: '' };

    const phoneNumberArray: Array<string> = fullPhoneNumber.split(' ');
    if (phoneNumberArray[0].length <= 4 && phoneNumberArray.length === 1)
      return { prefix: phoneNumberArray[0], phone_number: '' };
    if (phoneNumberArray[0].length <= 4)
      return {
        prefix: phoneNumberArray[0],
        phone_number: phoneNumberArray.slice(1).join(' '),
      };
    return { prefix: '', phone_number: fullPhoneNumber };
  });

  const [addressClaim, setAddressClaim] = useState<Record<string, string>>({
    street_address: props.claims.find((claim) => claim.name === 'address').value?.street_address || '',
    locality: props.claims.find((claim) => claim.name === 'address').value?.locality || '',
    region: props.claims.find((claim) => claim.name === 'address').value?.region || '',
    postal_code: props.claims.find((claim) => claim.name === 'address').value?.postal_code || '',
    country: props.claims.find((claim) => claim.name === 'address').value?.country || '',
  });

  const [countrieList, setCountrieList] = useState(
    allCountries.map((countrie) => {
      return { value: countrie[0], label: countrie[0] };
    }),
  );

  const [regionsList, setRegionsList] = useState(() => {
    const userCountryObject: CountryData =
      allCountries[
        allCountries.findIndex(
          (country) => country[0] === props.claims.find((claim) => claim.name === 'address').value?.country,
        )
      ];
    if (!userCountryObject) return [];
    const regionsUserCountry = userCountryObject[2].map((region) => {
      return { value: region[0], label: region[0] };
    });
    return regionsUserCountry;
  });

  const [streetAddress, setStreetAddress] = useState<Record<string, string>>(() => {
    const fullStreetAddress = props.claims.find((claim) => claim.name === 'address').value?.street_address;
    if (!fullStreetAddress) return { street_address: '', house_number: '' };

    const streetAddressArray = fullStreetAddress.split(' ');
    const streetAddressHasHouseNumber = !isNaN(Number(streetAddressArray.at(-1)));
    if (streetAddressHasHouseNumber)
      return {
        street_address: streetAddressArray.slice(0, -1).join(' '),
        house_number: streetAddressArray.at(-1),
      };

    return { street_address: streetAddressArray.join(' '), house_number: '' };
  });

  useEffect(() => {
    const phoneNumberValue =
      phoneNumber.prefix && phoneNumber.phone_number
        ? phoneNumber.prefix + ' ' + phoneNumber.phone_number
        : phoneNumber.prefix + phoneNumber.phone_number;

    props.setClaims(
      [...props.claims].map((claim) => (claim.name === 'phone_number' ? { ...claim, value: phoneNumberValue } : claim)),
    );
  }, [phoneNumber]);

  useEffect(() => {
    props.setClaims(
      [...props.claims].map((claim) => (claim.name === 'address' ? { ...claim, value: addressClaim } : claim)),
    );
  }, [addressClaim]);

  useEffect(() => {
    const addressValue =
      streetAddress.street_address && streetAddress.house_number
        ? streetAddress.street_address + ' ' + streetAddress.house_number
        : streetAddress.street_address + streetAddress.house_number;
    setAddressClaim({
      ...addressClaim,
      street_address: addressValue,
    });
  }, [streetAddress]);

  useEffect(() => {
    const userCountry = allCountries[allCountries.findIndex((country) => country[0] === addressClaim.country)];
    setRegionsList(() => {
      if (!userCountry) return [];
      const regionsFromCountry = userCountry[2].map((region) => {
        return { value: region[0], label: region[0] };
      });
      return regionsFromCountry;
    });

    if (!userCountry) return;
    const countryContainsCurrentRegion = userCountry[2].find((region) => region[0] === addressClaim.region);
    if (!countryContainsCurrentRegion) setAddressClaim({ ...addressClaim, region: '' });
  }, [addressClaim.country]);

  return (
    <>
      <TextValueInput
        name="username"
        title="Username"
        inputValueInvalide={props.invalideCalims?.some((claim) => claim.name === 'username')}
        invalideMessage={'please enter a username!'}
        required={true}
        textValue={props.username}
        setTextValue={(inputValue) => props.setUsername(inputValue)}
      />
      <PasswordInput
        password={props.password}
        setPassword={(inputValue) => props.setPassword(inputValue)}
        userCreation={props.userCreation}
      />
      <div className="flex">
        <div className="w-11/12">
          <TextValueInput
            name="email"
            title="Email"
            type="email"
            inputValueInvalide={props.invalideCalims?.some((claim) => claim.name === 'email')}
            invalideMessage={'Please enter a valid email address!'}
            textValue={props.claims.find((claim) => claim.name === 'email').value}
            placeholder={props.placeholderActive ? 'example.user@provider.de' : ''}
            setTextValue={(inputValue) =>
              props.setClaims(
                [...props.claims].map((claim) => (claim.name === 'email' ? { ...claim, value: inputValue } : claim)),
              )
            }
          />
        </div>
        <div className="w-1/12 text-center h-auto flex flex-col">
          <label className="block text-sm font-medium text-gray-700 mb-1.5">Verified</label>
          <div id="email_verified" className="m-auto">
            <ToggleSwitch
              aria-describedby="claim-value"
              checked={props.claims.find((claim) => claim.name === 'email_verified').value}
              onToggle={() =>
                props.setClaims(
                  [...props.claims].map((claim) =>
                    claim.name === 'email_verified' ? { ...claim, value: !claim.value } : claim,
                  ),
                )
              }
            />
          </div>
        </div>
      </div>
      <div className="border-2 h-1"></div>
      <h4>Personal Information</h4>
      <TextValueInput
        name="preferredUsername"
        title="Preferred Username"
        textValue={props.claims.find((claim) => claim.name === 'preferred_username').value}
        placeholder={props.placeholderActive ? 'Max Muster' : ''}
        setTextValue={(inputValue) =>
          props.setClaims(
            [...props.claims].map((claim) =>
              claim.name === 'preferred_username' ? { ...claim, value: inputValue } : claim,
            ),
          )
        }
      />
      <div className="flex">
        <div className="w-full">
          <TextValueInput
            name="familyName"
            title="Family Name"
            textValue={props.claims.find((claim) => claim.name === 'family_name').value}
            placeholder={props.placeholderActive ? 'Mann' : ''}
            setTextValue={(inputValue) =>
              props.setClaims(
                [...props.claims].map((claim) =>
                  claim.name === 'family_name' ? { ...claim, value: inputValue } : claim,
                ),
              )
            }
          />
        </div>
        <div className="w-full pl-3">
          <TextValueInput
            name="middleName"
            title="Middle Name"
            textValue={props.claims.find((claim) => claim.name === 'middle_name').value}
            placeholder={props.placeholderActive ? 'Muster' : ''}
            setTextValue={(inputValue) =>
              props.setClaims(
                [...props.claims].map((claim) =>
                  claim.name === 'middle_name' ? { ...claim, value: inputValue } : claim,
                ),
              )
            }
          />
        </div>
        <div className="w-full pl-3">
          <TextValueInput
            name="givenName"
            title="First Name"
            textValue={props.claims.find((claim) => claim.name === 'given_name').value}
            placeholder={props.placeholderActive ? 'Max' : ''}
            setTextValue={(inputValue) =>
              props.setClaims(
                [...props.claims].map((claim) =>
                  claim.name === 'given_name' ? { ...claim, value: inputValue } : claim,
                ),
              )
            }
          />
        </div>
      </div>
      <TextValueInput
        name="nickname"
        title="Nickname"
        textValue={props.claims.find((claim) => claim.name === 'nickname').value}
        placeholder={props.placeholderActive ? 'Mustermann' : ''}
        setTextValue={(inputValue) =>
          props.setClaims(
            [...props.claims].map((claim) => (claim.name === 'nickname' ? { ...claim, value: inputValue } : claim)),
          )
        }
      />
      <TextValueInput
        name="birthdate"
        title="Birthdate"
        type="date"
        inputValueInvalide={props.invalideCalims?.some((claim) => claim.name === 'birthdate')}
        invalideMessage={'Please enter a valid birthdate!'}
        textValue={props.claims.find((claim) => claim.name === 'birthdate').value}
        setTextValue={(inputValue) =>
          props.setClaims(
            [...props.claims].map((claim) => (claim.name === 'birthdate' ? { ...claim, value: inputValue } : claim)),
          )
        }
      />
      <div className="h-auto flex flex-col">
        <label className="text-gray-700 mb-3 text-sm font-medium">Gender</label>
        <div className="flex flex-row justify-between w-4/12 text-sm font-medium ml-1">
          <div className="flex items-center">
            <input
              type="radio"
              id="male"
              name="gender"
              checked={props.claims.find((claim) => claim.name === 'gender').value === 'male'}
              onChange={() =>
                props.setClaims(
                  [...props.claims].map((claim) => (claim.name === 'gender' ? { ...claim, value: 'male' } : claim)),
                )
              }
            ></input>
            <label className="pl-2" htmlFor="male">
              Male
            </label>
          </div>
          <div className="flex items-center">
            <input
              type="radio"
              id="female"
              name="gender"
              checked={props.claims.find((claim) => claim.name === 'gender').value === 'female'}
              onChange={() =>
                props.setClaims(
                  [...props.claims].map((claim) => (claim.name === 'gender' ? { ...claim, value: 'female' } : claim)),
                )
              }
            ></input>
            <label className="pl-2" htmlFor="female">
              Female
            </label>
          </div>
          <div className="flex items-center">
            <input
              type="radio"
              id="diverse"
              name="gender"
              checked={props.claims.find((claim) => claim.name === 'gender').value === 'diverse'}
              onChange={() =>
                props.setClaims(
                  [...props.claims].map((claim) => (claim.name === 'gender' ? { ...claim, value: 'diverse' } : claim)),
                )
              }
            ></input>
            <label className="pl-2" htmlFor="diverse">
              Diverse
            </label>
          </div>
        </div>
      </div>
      <div className="flex">
        <div className="w-1/12">
          <TextValueInput
            name="prefix"
            title="Prefix"
            type="tel"
            textValue={phoneNumber.prefix}
            placeholder={props.placeholderActive ? '49' : ''}
            setTextValue={(inputValue) => setPhoneNumber({ ...phoneNumber, prefix: inputValue })}
          />
        </div>
        <div className="w-10/12 pl-3">
          <TextValueInput
            name="phoneNumber"
            title="Phone Number"
            type="tel"
            textValue={phoneNumber.phone_number}
            placeholder={props.placeholderActive ? '30901820' : ''}
            inputValueInvalide={props.invalideCalims?.some((claim) => claim.name === 'phone_number')}
            invalideMessage={'Please enter a valid phone number!'}
            setTextValue={(inputValue) => setPhoneNumber({ ...phoneNumber, phone_number: inputValue })}
          />
        </div>
        <div className="w-1/12 text-center h-auto flex flex-col">
          <label className="block text-sm font-medium text-gray-700 mb-1.5">Verified</label>
          <div id="phone_number_verified" className="m-auto">
            <ToggleSwitch
              aria-describedby="claim-value"
              checked={props.claims.find((claim) => claim.name === 'phone_number_verified').value}
              onToggle={() =>
                props.setClaims(
                  [...props.claims].map((claim) =>
                    claim.name === 'phone_number_verified' ? { ...claim, value: !claim.value } : claim,
                  ),
                )
              }
            />
          </div>
        </div>
      </div>
      <TextValueInput
        name="company"
        title="Company Name"
        textValue={props.claims.find((claim) => claim.name === 'company').value}
        placeholder={props.placeholderActive ? 'ExampleCompany GmbH' : ''}
        setTextValue={(inputValue) =>
          props.setClaims(
            [...props.claims].map((claim) => (claim.name === 'company' ? { ...claim, value: inputValue } : claim)),
          )
        }
      />
      <div className="border-2 h-1"></div>
      <h4>Address</h4>
      <div className="flex">
        <div className="w-2/3">
          <TextValueInput
            name="streetAddress"
            title="Street Address"
            textValue={streetAddress.street_address}
            placeholder={props.placeholderActive ? 'Musterstrasse' : ''}
            setTextValue={(inputValue) => setStreetAddress({ ...streetAddress, street_address: inputValue })}
          />
        </div>
        <div className="w-1/3	pl-3">
          <TextValueInput
            name="streetHouseNumber"
            title="House Number"
            textValue={streetAddress.house_number}
            placeholder={props.placeholderActive ? '01' : ''}
            setTextValue={(inputValue) => setStreetAddress({ ...streetAddress, house_number: inputValue })}
          />
        </div>
      </div>
      <div className="flex">
        <div className="w-1/3">
          <TextValueInput
            name="postalCode"
            title="Postal Code"
            textValue={addressClaim.postal_code}
            placeholder={props.placeholderActive ? '44444' : ''}
            setTextValue={(inputValue) => setAddressClaim({ ...addressClaim, postal_code: inputValue })}
          />
        </div>
        <div className="w-2/3	pl-3">
          <TextValueInput
            name="locality"
            title="City"
            textValue={addressClaim.locality}
            placeholder={props.placeholderActive ? 'Musterstadt' : ''}
            setTextValue={(inputValue) => setAddressClaim({ ...addressClaim, locality: inputValue })}
          />
        </div>
      </div>
      <div className="h-auto flex flex-col w-full">
        <label className="block text-sm font-medium text-gray-700 mb-1.5">Country</label>
        <div className="w-full">
          <Select
            id={`country`}
            value={countrieList[countrieList.findIndex((country) => country.value === addressClaim.country)]}
            options={countrieList}
            onChange={(country) => setAddressClaim({ ...addressClaim, country: country!.value })}
            isSearchable={true}
            menuPlacement="auto"
            maxMenuHeight={250}
            styles={reactSelectStyling}
          />
        </div>
      </div>
      <div className="h-auto flex flex-col w-full">
        <label className="block text-sm font-medium text-gray-700 mb-1.5">Region</label>
        <div className="w-full">
          <Select
            id={`region`}
            key={`select_key__${addressClaim.region}`}
            value={regionsList[regionsList.findIndex((region) => region.value === addressClaim.region)] || ''}
            options={regionsList}
            onChange={(region) => setAddressClaim({ ...addressClaim, region: region!.value })}
            isSearchable={true}
            menuPlacement="auto"
            maxMenuHeight={250}
            styles={reactSelectStyling}
          />
        </div>
      </div>
      <div className="border-2 h-1"></div>
      <h4>Additional Information</h4>
      <TextValueInput
        name="picture"
        title="Picture URL"
        type="url"
        tooltipActive={true}
        tooltipMessage="URL of the user's profile picture. This url must refer to an image file (for example, a PNG, JPEG, or GIF image file), rather than to a web page containing an image"
        tooltipRight={true}
        textValue={props.claims.find((claim) => claim.name === 'picture').value}
        placeholder={props.placeholderActive ? '/Users/mustermann/documents/musterpicture.jpeg' : ''}
        setTextValue={(inputValue) =>
          props.setClaims(
            [...props.claims].map((claim) => (claim.name === 'picture' ? { ...claim, value: inputValue } : claim)),
          )
        }
      />
      <TextValueInput
        name="profile"
        title="Profile URL"
        type="url"
        tooltipActive={true}
        tooltipMessage="URL of the user's profile page. The contents of this web page should be about the user"
        tooltipRight={true}
        textValue={props.claims.find((claim) => claim.name === 'profile').value}
        placeholder={props.placeholderActive ? 'www.Muster-Profile.de' : ''}
        setTextValue={(inputValue) =>
          props.setClaims(
            [...props.claims].map((claim) => (claim.name === 'profile' ? { ...claim, value: inputValue } : claim)),
          )
        }
      />
      <TextValueInput
        name="website"
        title="Website URL"
        type="url"
        tooltipActive={true}
        tooltipMessage="URL of the user's web page or blog. This web page should contain information published by the user or an organization that the user is affiliated with"
        tooltipRight={true}
        textValue={props.claims.find((claim) => claim.name === 'website').value}
        placeholder={props.placeholderActive ? 'www.Muster-Mann.de' : ''}
        setTextValue={(inputValue) =>
          props.setClaims(
            [...props.claims].map((claim) => (claim.name === 'website' ? { ...claim, value: inputValue } : claim)),
          )
        }
      />
      <TextValueInput
        name="locale"
        title="Locale"
        tooltipActive={true}
        tooltipMessage="User's locale, represented as a BCP47 [RFC5646] language tag"
        tooltipRight={true}
        textValue={props.claims.find((claim) => claim.name === 'locale').value}
        placeholder={props.placeholderActive ? 'de-DE' : ''}
        setTextValue={(inputValue) =>
          props.setClaims(
            [...props.claims].map((claim) => (claim.name === 'locale' ? { ...claim, value: inputValue } : claim)),
          )
        }
      />
      <TextValueInput
        name="zoneinfo"
        title="Zoneinfo"
        tooltipActive={true}
        tooltipMessage="User's time zone"
        tooltipRight={true}
        textValue={props.claims.find((claim) => claim.name === 'zoneinfo').value}
        placeholder={props.placeholderActive ? 'Europe/Berlin' : ''}
        setTextValue={(inputValue) =>
          props.setClaims(
            [...props.claims].map((claim) => (claim.name === 'zoneinfo' ? { ...claim, value: inputValue } : claim)),
          )
        }
      />
    </>
  );
}
