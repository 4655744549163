import { FrontendExtensionModuleExport } from '@5minds/processcube_authority_sdk';

import './../../index.scss';
import {
  AddUserPage,
  ChangePasswordPage,
  ClaimsPage,
  CookieRedirectPage,
  Dashboard,
  EditGroupPage,
  EditUserPage,
  GroupsPage,
  LoginRedirectPage,
  PageFlowPromptLogin,
  ScopesPage,
} from './pages';

const upeFrontendExport: FrontendExtensionModuleExport = {
  onLoad: (authority) => {
    authority.componentRegistry.register('page:login_username_password', PageFlowPromptLogin);
    authority.componentRegistry.register('page:changePassword', ChangePasswordPage);
    authority.componentRegistry.register('page:admin', Dashboard);
    authority.componentRegistry.register('page:adminAddUser', AddUserPage);
    authority.componentRegistry.register('page:adminEditUser', EditUserPage);
    authority.componentRegistry.register('page:adminScopes', ScopesPage);
    authority.componentRegistry.register('page:adminClaims', ClaimsPage);
    authority.componentRegistry.register('page:adminGroups', GroupsPage);
    authority.componentRegistry.register('page:adminEditGroup', EditGroupPage);
    authority.componentRegistry.register('page:getToken', LoginRedirectPage);
    authority.componentRegistry.register('page:setCookie', CookieRedirectPage);
  },
};

export default upeFrontendExport;
