import { Transition } from '@headlessui/react';
import { CheckCircleIcon, ExclamationTriangleIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { Fragment, useEffect, useState } from 'react';

type SuccessNotificationProps = {
  message: string | null;
  setMessage: (message: string | null) => void;
  autoHide?: boolean;
  autoHideDelay?: number;
};

type ErrorNotificationProps = {
  message: string | null;
  setMessage: (message: string | null) => void;
};

type NotificationProps = {
  message: string | null;
  show: boolean;
  hide: () => void;
  icon?: JSX.Element;
};

export function SuccessNotification(props: SuccessNotificationProps): JSX.Element {
  const { message, setMessage, autoHide = false, autoHideDelay = 7000 } = props;
  const [autoHideRemaining, setAutoHideRemaining] = useState<number>(autoHideDelay);

  useEffect(() => {
    setAutoHideRemaining(autoHideDelay);
  }, [message, autoHideDelay]);

  useEffect(() => {
    if (autoHideRemaining <= 0) {
      setMessage(null);
    }
  }, [autoHideRemaining, setMessage]);

  useEffect(() => {
    if (autoHide && message !== null) {
      const interval = setInterval(() => {
        setAutoHideRemaining((remaining) => remaining - 1000);
      }, 1000);

      return () => clearInterval(interval);
    }

    return;
  }, [autoHide, message]);

  return (
    <Notification
      icon={<CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />}
      message={props.message}
      show={message !== null}
      hide={() => setMessage(null)}
    />
  );
}

export function ErrorNotification(props: ErrorNotificationProps): JSX.Element {
  const { message, setMessage } = props;

  return (
    <Notification
      icon={<ExclamationTriangleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />}
      message={props.message}
      show={message !== null}
      hide={() => setMessage(null)}
    />
  );
}

export function Notification(props: NotificationProps): JSX.Element {
  const { message, show, hide } = props;

  return (
    <div
      aria-live="assertive"
      className="z-[99] pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
    >
      <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
        <Transition
          show={show}
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            id="notification"
            className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
          >
            <div className="p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  {props.icon ? (
                    props.icon
                  ) : (
                    <InformationCircleIcon className="h-6 w-6 text-blue-400" aria-hidden="true" />
                  )}
                </div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <p className="text-sm font-medium text-gray-900">{message}</p>
                </div>
                <div className="ml-4 flex flex-shrink-0">
                  <button
                    type="button"
                    className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-5minds-blue-500 focus:ring-offset-2"
                    onClick={hide}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
}
