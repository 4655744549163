import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useState } from 'react';

import { SearchBar } from '../../../components';
import { FrontendGroup } from '../../../contracts';
import { filterGroups } from '../../../infrastructure';
import { UserGroupsRenderer } from './UserGroupsRenderer';

type AddUserGroupsProps = {
  groups: FrontendGroup[];
  save: (groups: FrontendGroup[]) => void;
};

export function AddUserGroups(props: AddUserGroupsProps): JSX.Element {
  const [groups, setGroups] = useState<FrontendGroup[]>(props.groups);

  const [filteredGroups, setFilteredGroups] = useState<FrontendGroup[]>(groups);
  const [search, setSearch] = useState<string>('');

  function toggleGroup(group: FrontendGroup) {
    group.enabled = group.enabled === undefined ? true : !group.enabled;

    setGroups([...groups]);
  }

  function filterGroupsAndClaims(filterTerm: string) {
    const filteredGroups = filterGroups(filterTerm, groups);
    setFilteredGroups(filteredGroups);
  }

  useEffect(() => {
    filterGroupsAndClaims(search);
  }, [groups]);

  useEffect(() => {
    debouncedFilter(search);
  }, [search]);

  const debouncedFilter = useCallback(
    debounce((search: string) => {
      filterGroupsAndClaims(search);
    }, 250),
    [],
  );

  useEffect(() => {
    props.save(groups);
  }, [groups]);

  return (
    <>
      <div className="relative">
        <div className="absolute right-5 top-1 z-50">
          <SearchBar onChange={(event) => setSearch(event.target.value)} />
        </div>
      </div>
      <div className="flex h-full overflow-auto border border-gray-200 border-t-transparent rounded-b-lg">
        <UserGroupsRenderer groups={filteredGroups} toggleGroup={toggleGroup} />
      </div>
    </>
  );
}
