import React from 'react';

import { SearchBar } from '../../../components';
import { FrontendScope, FrontendUserClaim } from '../../../contracts';
import { UserClaimsRenderer } from './UserClaimsRenderer';
import { UserScopesRenderer } from './UserScopesRenderer';

type EditUserScopesProps = {
  claims: FrontendUserClaim[];
  scopes: FrontendScope[];
  changeClaim: (name: string, value: any) => void;
  getEnabledScopesByClaim: (claim: FrontendUserClaim) => FrontendScope[];
  setSearch: (search: string) => void;
  toggleClaim: (name: string) => void;
  toggleScope: (name: string) => void;
};

export function EditUserPermissions(props: EditUserScopesProps): JSX.Element {
  return (
    <>
      <div className="relative">
        <div className="flex gap-2 absolute right-0 top-[-3rem]">
          <SearchBar onChange={(event) => props.setSearch(event.target.value)} />
        </div>
      </div>
      <div className="flex h-full overflow-auto border border-gray-200 rounded-b-lg rounded-r-lg">
        <UserScopesRenderer scopes={props.scopes} claims={props.claims} toggleScope={props.toggleScope} />
        <UserClaimsRenderer
          claims={props.claims}
          toggleClaim={props.toggleClaim}
          changeClaim={props.changeClaim}
          getEnabledScopesByClaim={props.getEnabledScopesByClaim}
        />
      </div>
    </>
  );
}
