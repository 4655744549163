import debounce from 'lodash.debounce';
import { useCallback, useEffect, useState } from 'react';

import { Claim, Group, Scope } from '@5minds/processcube_authority_sdk';

import { ErrorNotification, Step, StepStatus, WithDefaultNavBar, WithSteps } from '../../../components';
import { FrontendClaim, FrontendGroup, FrontendScope, FrontendUserClaim } from '../../../contracts';
import { parseGroups, parseUserClaims, parseUserScopes, sortByName } from '../../../infrastructure';
import { AddUserAccount } from './AddUserAccount';
import { AddUserConfirm } from './AddUserConfirm';
import { AddUserGroups } from './AddUserGroups';
import { AddUserPermissions } from './AddUserPermissions';

type AddUserPageProps = {
  routerPrefix: string;
  logo: string;
  issuerUrl: string;
  groups: Group[];
  scopes: Scope[];
  claims: Claim[];
  scopelessClaims: Claim[];
};

export function AddUserPage(props: AddUserPageProps): JSX.Element {
  const [steps, setSteps] = useState<Step[]>([
    {
      id: 'Step 1',
      name: 'Account',
      status: StepStatus.CURRENT,
    },
    {
      id: 'Step 2',
      name: 'Groups',
      status: StepStatus.UPCOMING,
    },
    {
      id: 'Step 3',
      name: 'Permissions',
      status: StepStatus.UPCOMING,
    },
    {
      id: 'Step 4',
      name: 'Confirm',
      status: StepStatus.UPCOMING,
    },
  ]);

  const parsedGroups = parseGroups(props.groups);
  const parsedScopes = parseUserScopes(props.scopes.sort(sortByName));
  const parsedClaims = parseUserClaims(props.claims);

  const oidcScopes = ['address', 'email', 'openid', 'phone', 'profile'];

  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [groups, setGroups] = useState<FrontendGroup[]>(parsedGroups);
  const [scopes, setScopes] = useState<FrontendScope[]>(
    parsedScopes.map((scope) => (oidcScopes.includes(scope.name) ? { ...scope, enabled: true } : scope)),
  );

  const [oidcClaimNames, setOidcClaimNames] = useState<Array<string>>(() => {
    const oidcScopeNames: Array<string> = [];
    parsedScopes.forEach((scope) => {
      if (oidcScopes.includes(scope.name)) {
        oidcScopeNames.push(...scope.claims);
      }
    });
    return oidcScopeNames;
  });

  const [claims, setClaims] = useState<FrontendUserClaim[]>(() =>
    parsedClaims.map((claim) => {
      if (claim.name === 'phone_number_verified' || claim.name === 'email_verified')
        return { ...claim, enabled: true, value: false };
      if (oidcClaimNames.includes(claim.name)) return { ...claim, enabled: true };
      return claim;
    }),
  );

  const [error, setError] = useState<string | null>(null);
  const [requiredData, setRequiredData] = useState({
    usernameSet: false,
    passwordSet: false,
  });

  useEffect(() => {
    setRequiredData({ usernameSet: username.length !== 0, passwordSet: password.length !== 0 });
  }, [username, password]);

  const debouncedUpdateDetails = useCallback(debounce(updateDetails, 100), []);
  const debouncedUpdateGroups = useCallback(debounce(updateGroups, 100), []);
  const debouncedUpdatePermissions = useCallback(debounce(updatePermissions, 100), []);

  function updateDetails(username: string, password: string, claims: Array<FrontendUserClaim>) {
    setUsername(username);
    setPassword(password);
    setClaims(claims);
  }

  function updateGroups(groups: FrontendGroup[]) {
    setGroups(groups);
    const enabledGroups = groups.filter((group) => group.enabled);
    const scopeNamesFromEnabledGroups = enabledGroups.flatMap((group) => group.scopeNames);
    scopes.forEach((scope) => {
      const scopeFromGroup = scopeNamesFromEnabledGroups.includes(scope.name);
      scope.fromGroup = scopeFromGroup ? true : false;
    });
    updateClaims();
  }

  function updateClaims() {
    const enabledScopes = scopes.filter((scope) => scope.enabled || scope.fromGroup);
    const enabledClaimNames = enabledScopes.flatMap((scope) => scope.claims);
    claims.forEach((claim) => {
      claim.enabled = enabledClaimNames.includes(claim.name);
    });
  }

  function updatePermissions(scopes: FrontendScope[], claims: FrontendUserClaim[]) {
    setScopes(scopes);
    setClaims(claims);
  }

  function renderStepContent(name: string) {
    switch (name) {
      case 'Confirm':
        return (
          <AddUserConfirm
            routerPrefix={props.routerPrefix}
            user={{
              username,
              password,
              groups: groups.filter((g) => g.enabled),
              scopes: scopes.filter((s) => s.enabled),
              claims: claims.filter((c) => c.enabled),
            }}
            setError={setError}
          />
        );
      case 'Groups':
        return <AddUserGroups groups={groups} save={(groups) => debouncedUpdateGroups(groups)} />;
      case 'Permissions':
        return (
          <AddUserPermissions
            scopes={scopes}
            claims={claims}
            save={(scopes, claims) => debouncedUpdatePermissions(scopes, claims)}
          />
        );
      default:
        return (
          <AddUserAccount
            username={username}
            password={password}
            save={(username, password, claims) => debouncedUpdateDetails(username, password, claims)}
            claims={claims}
          />
        );
    }
  }

  return (
    <>
      <ErrorNotification message={error} setMessage={setError} />
      <WithDefaultNavBar issuerUrl={props.issuerUrl} logo={props.logo} routerPrefix={props.routerPrefix}>
        <WithSteps steps={steps} setSteps={setSteps} requiredData={requiredData}>
          {renderStepContent(steps.find((s) => s.status === StepStatus.CURRENT)?.name || '')}
        </WithSteps>
      </WithDefaultNavBar>
    </>
  );
}
