import React from 'react';

import './Inputs.scss';

interface IdRendererProps {
  title: string;
  id: string;
}

export function IdRenderer(props: IdRendererProps): JSX.Element {
  return (
    <div>
      <label htmlFor="id" className="text-input-label">
        {props.title}
      </label>
      <div className="mt-1">
        <div className="block w-full rounded-md border-0 py-1.5 px-3 shadow-sm ring-1 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 cursor-not-allowed bg-gray-50 text-gray-500 ring-gray-200 sm:text-sm sm:leading-6">
          {props.id}
        </div>
      </div>
    </div>
  );
}
