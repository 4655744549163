import React from 'react';

import { FrontendScope, FrontendUserClaim } from '../../../contracts';
import { OIDC_CLAIM_NAMES } from '../../../infrastructure';
import { UserClaimRenderer } from './UserClaimRenderer';

type UserClaimsRendererProps = {
  claims: FrontendUserClaim[];
  toggleClaim: (name: string) => void;
  changeClaim: (name: string, value: any) => void;
  getEnabledScopesByClaim: (claim: FrontendUserClaim) => FrontendScope[];
  createUser?: boolean;
};

export function UserClaimsRenderer(props: UserClaimsRendererProps): JSX.Element {
  const oidcClaimNames = OIDC_CLAIM_NAMES;
  const customClaims = props.claims.filter((claim) => !oidcClaimNames.includes(claim.name));

  return (
    <div className="w-full h-full overflow-y-auto box-border p-4">
      <h2 className="mb-4 text-2xl font-bold leading-6 text-gray-900">Claims</h2>
      <div className="flex flex-col gap-2">
        {customClaims.map((claim) => (
          <UserClaimRenderer
            key={claim.id}
            claim={claim}
            onChange={props.changeClaim}
            getEnabledScopesByClaim={props.getEnabledScopesByClaim}
            claimOriginForClaimId={'custom'}
          />
        ))}
      </div>
      <hr className="pb-4"></hr>
    </div>
  );
}
