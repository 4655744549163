import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useState } from 'react';

import { createDefaultClaimValue } from '@5minds/processcube_authority_sdk';

import { SearchBar } from '../../../components';
import { FrontendScope, FrontendUserClaim } from '../../../contracts';
import { filterScopes, filterUserClaims } from '../../../infrastructure';
import { UserClaimsRenderer } from './UserClaimsRenderer';
import { UserScopesRenderer } from './UserScopesRenderer';

type AddUserScopesProps = {
  scopes: FrontendScope[];
  claims: FrontendUserClaim[];
  save: (scopes: FrontendScope[], claims: FrontendUserClaim[]) => void;
};

export function AddUserPermissions(props: AddUserScopesProps): JSX.Element {
  const [scopes, setScopes] = useState<FrontendScope[]>(props.scopes);
  const [claims, setClaims] = useState<FrontendUserClaim[]>(props.claims);

  const [filteredScopes, setFilteredScopes] = useState<FrontendScope[]>(scopes);
  const [filteredClaims, setFilteredClaims] = useState<FrontendUserClaim[]>(claims);
  const [search, setSearch] = useState<string>('');

  function toggleScope(name: string) {
    const scope = scopes.find((s) => s.name === name);

    if (!scope) return;

    scope.enabled = !scope.enabled;

    scope.claims.forEach((claimName) => {
      const claim = claims.find((c) => c.name === claimName);
      if (claim) {
        claim.enabled = scope.enabled;
        claim.value = claim.value ?? createDefaultClaimValue(claim.type);
      }
    });

    setScopes([...scopes]);
    setClaims([...claims]);
  }

  function toggleClaim(name: string) {
    const claim = claims.find((c) => c.name === name);
    if (claim) {
      claim.enabled = !claim.enabled;
      setClaims([...claims]);
    }
  }

  function changeClaim(name: string, value: any) {
    const claim = claims.find((c) => c.name === name);
    if (!claim) return;

    claim.value = value;
    setClaims([...claims]);
  }

  function getEnabledScopesByClaim(claim: FrontendUserClaim): FrontendScope[] {
    return scopes.filter((scope) => scope.enabled && scope.claims.includes(claim.name));
  }

  function filterScopesAndClaims(filterTerm: string) {
    const filteredClaims = filterUserClaims(filterTerm, claims);
    const includedClaims = filteredClaims.map((c) => c.name.toLowerCase());
    const filteredScopes = filterScopes(filterTerm, scopes, includedClaims);
    setFilteredScopes(filteredScopes);
    setFilteredClaims(filteredClaims);
  }

  useEffect(() => {
    filterScopesAndClaims(search);
  }, [scopes, claims]);

  useEffect(() => {
    debouncedFilter(search);
  }, [search]);

  const debouncedFilter = useCallback(
    debounce((search: string) => {
      filterScopesAndClaims(search);
    }, 250),
    [],
  );

  useEffect(() => {
    props.save(scopes, claims);
  }, [scopes, claims]);

  return (
    <>
      <div className="relative">
        <div className="absolute right-5 top-1 z-50">
          <SearchBar onChange={(event) => setSearch(event.target.value)} />
        </div>
      </div>
      <div className="flex h-full overflow-auto border border-gray-200 border-t-transparent rounded-b-lg">
        <UserScopesRenderer scopes={filteredScopes} claims={filteredClaims} toggleScope={toggleScope} />
        <UserClaimsRenderer
          claims={filteredClaims}
          toggleClaim={toggleClaim}
          changeClaim={changeClaim}
          getEnabledScopesByClaim={getEnabledScopesByClaim}
          createUser={true}
        />
      </div>
    </>
  );
}
