import { ClientMetadata } from 'oidc-provider';
import React, { useEffect } from 'react';

import { createUserManager } from '../infrastructure';

type GetTokenRedirectPageProps = {
  clientData: ClientMetadata;
  redirectUri: string;
  issuerUrl: string;
};

export function LoginRedirectPage({ clientData, redirectUri, issuerUrl }: GetTokenRedirectPageProps): JSX.Element {
  useEffect(() => {
    const userManager = createUserManager(clientData, redirectUri, issuerUrl);
    userManager.signinRedirect();
  }, []);

  return <p>Redirecting...</p>;
}
