import React, { useEffect, useState } from 'react';

import { Group, User } from '@5minds/processcube_authority_sdk';

import { ErrorNotification, SuccessNotification, WithDefaultNavBar } from '../../../components';
import { getUrlParam, setUrlParam, sortByName } from '../../../infrastructure';
import { CreateGroupDialog } from './CreateGroupDialog';
import { DeleteGroupDialog } from './DeleteGroupDialog';
import { SelectGroupsRenderer } from './SelectGroupsRenderer';
import { SelectUsersRenderer } from './SelectUsersRenderer';

type GroupsPageProps = {
  routerPrefix: string;
  logo: string;
  issuerUrl: string;
  groups: Group[];
  users: User[];
};

export function GroupsPage(props: GroupsPageProps): JSX.Element {
  const [groups, setGroups] = useState<Group[]>(props.groups.length > 0 ? props.groups.sort(sortByName) : []);
  const [currentGroup, setCurrentGroup] = useState<Group | null>(
    props.groups.length > 0 ? groups.find((group) => group.name === getUrlParam('group')) || groups[0] : null,
  );
  const [unselectedUsers, setUnselectedUsers] = useState<User[]>(
    props.users.filter((user) =>
      currentGroup ? !currentGroup.users.map((user) => user.username).includes(user.username) : [],
    ),
  );

  const [groupFilter, setGroupFilter] = useState<string>('');
  const [userFilter, setUserFilter] = useState<string>('');

  const [selectedGroup, setSelectedGroup] = useState<Group | null>(props.groups.length > 0 ? props.groups[0] : null);
  const [showCreateDialog, setShowCreateDialog] = useState<boolean>(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [showUpdateDialog, setShowUpdateDialog] = useState<boolean>(false);

  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  function createGroup(name?: string, description?: string) {
    const body = JSON.stringify({ name, description });

    fetch(`${props.routerPrefix}/admin/group/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    }).then(async (response) => {
      if (!response.ok) {
        const error = await response.json();

        setError(`${error.additionalInformation.name}: ${error.message}`);
        return;
      }

      const createdGroup = await response.json();

      setGroups((groups) => [...groups, createdGroup].sort(sortByName));
      setCurrentGroup(createdGroup);
      setShowCreateDialog(false);
    });
  }

  function deleteGroup(groupName: string) {
    fetch(`${props.routerPrefix}/admin/group/${groupName}/delete`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(async (response) => {
      if (!response.ok) {
        const error = await response.json();

        setError(`${error.additionalInformation.name}: ${error.message}`);
        return;
      }

      const deletedGroup = await response.json();

      setGroups(groups.filter((group) => group.id !== deletedGroup.id));
      const deletedGroupWasOnlyGroup = groups.length === 1 && groups[0].name === deletedGroup.name;
      setCurrentGroup(deletedGroupWasOnlyGroup ? null : groups[0]);
      setShowDeleteDialog(false);
    });
  }

  function addUserToGroup(user: User) {
    if (currentGroup == null) {
      return;
    }
    fetch(`${props.routerPrefix}/admin/group/${currentGroup.name}/add/user`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username: user.username }),
    }).then(async (response) => {
      if (!response.ok) {
        const error = await response.json();

        setError(`${error.additionalInformation.name}: ${error.message}`);
        return;
      }

      const updatedGroup = await response.json();

      setGroups(groups.map((group) => (group.id === updatedGroup.id ? updatedGroup : group)).sort(sortByName));
      setCurrentGroup(updatedGroup);
      setShowUpdateDialog(false);
    });
  }

  function removeUserFromGroup(user: User) {
    if (currentGroup == null) {
      return;
    }
    fetch(`${props.routerPrefix}/admin/group/${currentGroup.name}/remove/user`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username: user.username }),
    }).then(async (response) => {
      if (!response.ok) {
        const error = await response.json();

        setError(`${error.additionalInformation.name}: ${error.message}`);
        return;
      }

      const updatedGroup = await response.json();

      setGroups(groups.map((group) => (group.id === updatedGroup.id ? updatedGroup : group)).sort(sortByName));
      setCurrentGroup(updatedGroup);
      setShowUpdateDialog(false);
    });
  }

  useEffect(() => {
    const filteredGroups = props.groups.filter((group) => group.name.includes(groupFilter));
    setGroups(filteredGroups.sort(sortByName));
  }, [groupFilter]);

  useEffect(() => {
    const newUnselectedUsers = props.users.filter((user) =>
      currentGroup
        ? !currentGroup.users.map((user) => user.username).includes(user.username) && user.username.includes(userFilter)
        : false,
    );
    setUnselectedUsers(newUnselectedUsers);
  }, [currentGroup, userFilter]);

  useEffect(() => {
    setUrlParam('group', currentGroup !== null ? currentGroup.name : '');
  }, [currentGroup, selectedGroup]);

  return (
    <>
      <SuccessNotification message={successMessage} setMessage={setSuccessMessage} autoHide />
      <ErrorNotification message={error} setMessage={setError} />
      <CreateGroupDialog show={showCreateDialog} hide={() => setShowCreateDialog(false)} createGroup={createGroup} />
      {selectedGroup !== null && (
        <DeleteGroupDialog
          group={selectedGroup}
          show={showDeleteDialog}
          hide={() => setShowDeleteDialog(false)}
          deleteGroup={deleteGroup}
        />
      )}

      <WithDefaultNavBar issuerUrl={props.issuerUrl} logo={props.logo} routerPrefix={props.routerPrefix}>
        <div className="mx-auto max-w-6xl h-full border-x border-gray-200">
          <div className="flex h-full">
            {' '}
            <div className="flex flex-col w-1/2 h-full border-r border-gray-200">
              <SelectGroupsRenderer
                currentGroup={currentGroup}
                groups={groups}
                routerPrefix={props.routerPrefix}
                setCurrentGroup={setCurrentGroup}
                setGroupFilter={setGroupFilter}
                setSelectedGroup={setSelectedGroup}
                showCreateDialog={() => setShowCreateDialog(true)}
                showDeleteDialog={() => setShowDeleteDialog(true)}
                showUpdateDialog={() => setShowUpdateDialog(true)}
              />
            </div>
            {currentGroup !== null && (
              <div className="flex flex-col w-1/2 h-full">
                <SelectUsersRenderer
                  currentGroup={currentGroup}
                  unselectedUsers={unselectedUsers}
                  addUserToGroup={addUserToGroup}
                  removeUserFromGroup={removeUserFromGroup}
                  setUserFilter={setUserFilter}
                />
              </div>
            )}
          </div>
        </div>
      </WithDefaultNavBar>
    </>
  );
}
