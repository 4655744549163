import { ClaimTypes, UserClaim } from '@5minds/processcube_authority_sdk';

export function claimHasChanges(currentClaim: UserClaim, oldClaim: UserClaim | undefined): boolean {
  if (!oldClaim) return false;
  if (currentClaim.type === ClaimTypes.OBJECT) {
    const claimValueWasChanged = JSON.stringify(currentClaim.value) !== JSON.stringify(oldClaim.value);
    if (claimValueWasChanged) {
      return true;
    }
    return false;
  }
  const claimValueWasChanged = currentClaim.value !== oldClaim.value;
  if (claimValueWasChanged) {
    return true;
  }
  return false;
}
