import { Card, ErrorContainer, PasswordInput } from '../components';

type ChangePasswordPageProps = {
  routerPrefix: string;
  message?: string;
};

export function ChangePasswordPage({ routerPrefix, message }: ChangePasswordPageProps) {
  return (
    <Card
      header={
        <div className="flex justify-between items-center">
          <h1 className="text-lg font-medium leading-6 text-gray-900">Change password</h1>
        </div>
      }
    >
      {message != null && (
        <div className="mb-4">
          <ErrorContainer message={message} />
        </div>
      )}
      <form method="post" action={`${routerPrefix ?? ''}/change_password`}>
        <PasswordInput name="oldPassword" title="Current password" />
        <PasswordInput name="newPassword" title="New password" />
        <PasswordInput name="newPasswordRepeated" title="Repeat new password" />
        <div className="flex justify-end">
          <input
            className="mt-4 inline-flex items-center rounded-md border border-transparent bg-5minds-blue-800 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-5minds-blue-900 focus:outline-none focus:ring-2 focus:ring-5minds-blue-900 focus:ring-offset-2"
            type="submit"
            value="Save"
          />
        </div>
      </form>
    </Card>
  );
}
