import { ClientMetadata } from 'oidc-provider';
import React, { useEffect } from 'react';

import { createUserManager } from '../infrastructure';

const setTokenCookies = async (routerPrefix: string, accessToken: string, idToken: string): Promise<void> => {
  await fetch(`${routerPrefix}/set_access_token_cookie`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      access_token: accessToken,
      id_token: idToken,
    }),
  });
};

const clearHashFromUrl = () => history.replaceState({}, '', window.location.pathname + window.location.search);

type CookieRedirectPageProps = {
  routerPrefix: string;
  clientData: ClientMetadata;
  redirectUri: string;
  issuerUrl: string;
};

export function CookieRedirectPage({
  clientData,
  redirectUri,
  issuerUrl,
  routerPrefix,
}: CookieRedirectPageProps): JSX.Element {
  useEffect(() => {
    const hash = window.location.hash.slice(1);
    clearHashFromUrl();

    const params = new URLSearchParams(hash);
    const accessToken = params.get('access_token');
    const idToken = params.get('id_token');

    if (accessToken && idToken) {
      setTokenCookies(routerPrefix, accessToken, idToken).then(
        () => (window.location.href = '/acr/username_password/admin'),
      );
    } else {
      const userManager = createUserManager(clientData, redirectUri, issuerUrl);
      userManager.signinRedirect();
    }
  }, []);

  return <div>Redirecting...</div>;
}
