import { Disclosure } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useRef } from 'react';

import { ScopesSidebarItem } from '../contracts';
import { getUrlParam, updateUrlParam } from '../infrastructure';
import { ToggleSwitch } from './ToggleSwitch';
import { ExpandTransition } from './Transitions';

type ScopeRendererProps = {
  open: boolean;
  scope: ScopesSidebarItem;
  toggleScope: (name: string) => void;
  disableToggle?: boolean;
};

export function ScopeRenderer({ open, scope, disableToggle, toggleScope }: ScopeRendererProps): JSX.Element {
  const divRef = useRef<HTMLButtonElement>(null);

  function focusScope() {
    divRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    updateUrlParam('scope', scope.name);
  }

  useEffect(() => {
    if (scope.name === getUrlParam('scope')) {
      focusScope();
    }
  }, [scope.name]);

  const scopeCheckMark = disableToggle ? (
    <div id="scope_check_mark" className="w-7 h-7 min-w-[1rem] min-h-[1rem] 5minds-blue-800">
      <CheckCircleIcon />
    </div>
  ) : (
    <ToggleSwitch checked={scope.enabled} onToggle={() => toggleScope(scope.name)} />
  );

  const scopeEditor = (
    <Disclosure.Button
      as="div"
      ref={divRef}
      onClick={focusScope}
      className={`hover:cursor-pointer group flex w-full items-center rounded-md py-2 focus:outline-none focus:ring-2 focus:ring-5minds-blue-900 ${
        open ? 'bg-gray-100 text-gray-900' : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900'
      }`}
    >
      <svg
        className={`${
          open ? 'rotate-90' : ''
        } text-gray-400 mr-2 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400`}
        viewBox="0 0 20 20"
        aria-hidden="true"
      >
        <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
      </svg>
      <div className={`ml-3 text-base font-medium select-none overflow-anywhere text-gray-700`}>{scope.name}</div>
      <div className="flex ml-auto gap-2 items-center">
        {!scope.enabled && (
          <span className="hidden md:block rounded-full whitespace-nowrap py-0.5 px-2.5 text-sm font-medium bg-5minds-blue-50 text-5minds-blue-900">
            {scope.claims.filter((claim) => claim.enabled).length} / {scope.claims.length}
          </span>
        )}
        {scopeCheckMark}
      </div>
    </Disclosure.Button>
  );

  const claimList = (
    <ExpandTransition show={open}>
      <Disclosure.Panel className="space-y-1">
        {scope.claims.map((claim) => (
          <div
            key={`${claim.name}-navitem-child`}
            className="gap-2 group flex w-full items-center rounded-md py-2 pl-10 pr-2 text-md font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900"
          >
            <a href={`#${claim.name}`} className="truncate">
              {claim.name}
            </a>
            {claim.enabled && (
              <div className="w-4 h-4 min-w-[1rem] min-h-[1rem] text-green-400">
                <CheckCircleIcon />
              </div>
            )}
          </div>
        ))}
      </Disclosure.Panel>
    </ExpandTransition>
  );

  return (
    <>
      {scopeEditor}
      {claimList}
    </>
  );
}
