import { Popover, Transition } from '@headlessui/react';
import React, { Fragment, PropsWithChildren, useEffect, useState } from 'react';

type TooltipProps = PropsWithChildren<{
  message: JSX.Element;
  show: boolean;
  rightAligned?: boolean;
  customTooltipPositionY?: string;
  setShow: (show: boolean) => void;
}>;

export function Tooltip(props: PropsWithChildren<TooltipProps>): JSX.Element {
  const tooltipPositionY = props.customTooltipPositionY ?? '-translate-y-9';
  const animationStartPositions = props.rightAligned ? '-translate-x-16' : 'translate-x-16';
  return (
    <Popover className="relative">
      <div onMouseEnter={() => props.setShow(true)} onMouseLeave={() => props.setShow(false)}>
        {props.children}
      </div>
      <div className={props.rightAligned ? '-translate-y-9' : '-translate-x-80' + ' ' + tooltipPositionY}>
        <Transition
          as={Fragment}
          show={props.show}
          enter="transition ease-out duration-200"
          enterFrom={`opacity-0 ${animationStartPositions}`}
          enterTo="opacity-100 translate-x-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-x-0"
          leaveTo={`opacity-0 ${animationStartPositions}`}
        >
          <Popover.Panel
            onMouseEnter={() => props.setShow(true)}
            onMouseLeave={() => props.setShow(false)}
            className="absolute flex px-8 z-50"
          >
            <div className="relative w-max max-w-[16rem] shrink rounded-xl bg-white p-4 text-sm leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5">
              {props.message}
            </div>
          </Popover.Panel>
        </Transition>
      </div>
    </Popover>
  );
}
