import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import React from 'react';

type SearchBarProps = {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
};

export function SearchBar(props: SearchBarProps): JSX.Element {
  return (
    <div className="flex flex-1 items-center justify-end bg-transparent">
      <div className="w-full max-w-lg lg:max-w-xs">
        <label htmlFor="search" className="sr-only">
          Search
        </label>
        <div className="relative">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            id="search"
            name="search"
            className="block w-full py-1.5 pl-10 pr-3 text-gray-900 rounded-md border-0 ring-1 ring-inset ring-gray-300 bg-white bg-opacity-40 focus:bg-opacity-100 hover:bg-opacity-100 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-5minds-blue-900 sm:text-sm sm:leading-6"
            placeholder="Search"
            type="search"
            onChange={props.onChange}
            value={props.value}
          />
        </div>
      </div>
    </div>
  );
}
