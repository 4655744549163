import { PencilSquareIcon, TrashIcon } from '@heroicons/react/20/solid';
import React, { useState } from 'react';

import { Claim } from '@5minds/processcube_authority_sdk';

import { Tooltip } from '../../../components';

export type ClaimRendererProps = {
  claim: Claim;
  setSelectedClaim: (claim: Claim) => void;
  showEditDialog: () => void;
  showDeleteDialog: () => void;
};

export function ClaimRenderer(props: ClaimRendererProps): JSX.Element {
  const { claim } = props;
  const isDefaultClaim = claim.isDefaultClaim;

  const [showDefaultEditClaimTooltip, setDefaultEditClaimTooltip] = useState(false);
  const [showDefaultDeleteClaimTooltip, setDefaultDeleteClaimTooltip] = useState(false);

  return (
    <div
      key={claim.id}
      className="flex justify-between py-2 items-center hover:bg-gray-50 position: relative"
      id="claimRowInClaimsTable"
    >
      <div className="flex flex-col w-2/3 text-sm leading-6">
        <div className="flex gap-3 items-baseline">
          <div id="claim-name" className="font-medium text-gray-900">
            {claim.name}
          </div>
          <p id="claim-type" className="text-gray-400 font-mono text-xs">
            {claim.type}
          </p>
        </div>
        <div id="claim-description" className="text-gray-500 truncate">
          {claim.description && claim.description.length > 0 ? claim.description : 'No description available'}
        </div>
      </div>
      <div className="flex gap-1">
        {!isDefaultClaim ? (
          <>
            <a
              className="text-5minds-blue-800 hover:text-5minds-blue-900 hover:cursor-pointer focus:outline-none focus:ring-2 focus:ring-5minds-blue-900"
              title="Edit"
              tabIndex={0}
              onClick={() => {
                props.setSelectedClaim(claim);
                props.showEditDialog();
              }}
            >
              <PencilSquareIcon className="h-5 w-5" aria-hidden="true" />
            </a>
            <a
              className="text-red-700 hover:text-red-800 hover:cursor-pointer focus:outline-none focus:ring-2 focus:ring-red-900"
              title="Delete"
              tabIndex={0}
              onClick={() => {
                props.setSelectedClaim(claim);
                props.showDeleteDialog();
              }}
            >
              <TrashIcon className="h-5 w-5" aria-hidden="true" />
            </a>
          </>
        ) : (
          <>
            <Tooltip
              message={<p>Default claims should not be altered!</p>}
              show={showDefaultEditClaimTooltip}
              setShow={setDefaultEditClaimTooltip}
            >
              <a
                className="text-gray-300 hover:text-gray-400 hover:cursor-pointer focus:outline-none"
                title="DefaultClaimEdit"
                tabIndex={0}
              >
                <PencilSquareIcon className="h-5 w-5" aria-hidden="true" />
              </a>
            </Tooltip>
            <Tooltip
              message={<p>Default claims should not be deleted!</p>}
              show={showDefaultDeleteClaimTooltip}
              setShow={setDefaultDeleteClaimTooltip}
            >
              <a
                className="text-gray-300 hover:text-gray-400 hover:cursor-pointer focus:outline-none"
                title="DefaultClaimDelete"
                tabIndex={0}
              >
                <TrashIcon className="h-5 w-5" aria-hidden="true" />
              </a>
            </Tooltip>
          </>
        )}
      </div>
    </div>
  );
}
