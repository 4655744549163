import React, { useState } from 'react';

import { Tooltip } from '../../../components';
import { FrontendScope, FrontendUserClaim } from '../../../contracts';
import { UserClaimEditor } from './UserClaimEditor';

interface UserClaimRendererProps {
  claim: FrontendUserClaim;
  onChange: (name: string, value: string | boolean) => void;
  getEnabledScopesByClaim: (claim: FrontendUserClaim) => FrontendScope[];
  claimOriginForClaimId: string;
}

export function UserClaimRenderer(props: UserClaimRendererProps): JSX.Element {
  const enabledScopes = props.getEnabledScopesByClaim(props.claim);
  const [showTooltip, setShowTooltip] = useState(false);

  const tooltipMessage = (
    <>
      Claim <span className="font-semibold">{props.claim.name}</span> is enabled because it is included in the following
      enabled scopes: <span className="font-semibold">{enabledScopes.map((s) => s.name).join(', ')}</span>
    </>
  );

  return (
    <div
      id={props.claimOriginForClaimId + '_claim_in_claim_table_' + props.claim.name}
      className="scroll-my-12 relative flex gap-2 h-12 justify-between items-center p-1 pl-3 rounded-lg hover:bg-gray-100 hover:no-underline focus:outline-none focus:ring-2 focus:ring-5minds-blue-900 focus:ring-offset-2"
      tabIndex={0}
    >
      <Tooltip message={tooltipMessage} show={props.claim.enabled && showTooltip} setShow={setShowTooltip}>
        <label
          htmlFor={`${props.claim.name}-toggle`}
          className={`ml-3 text-base font-medium select-none overflow-anywhere  ${
            props.claim.enabled ? 'text-gray-700' : 'text-gray-400'
          }`}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          {props.claim.name}
        </label>
      </Tooltip>
      <div className="flex justify-end gap-2 items-center w-2/5 h-full max-w-md">
        <UserClaimEditor claim={props.claim} updateClaim={(value) => props.onChange(props.claim.name, value)} />
      </div>
    </div>
  );
}
