import { MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/20/solid';
import React from 'react';

import { FrontendGroup } from '../../../contracts';

export type GroupRendererProps = {
  group: FrontendGroup;
  toggleGroup: (group: FrontendGroup) => void;
};

export function UserGroupRenderer(props: GroupRendererProps): JSX.Element {
  const { group, toggleGroup } = props;

  const toggleButton = group.enabled ? (
    <a
      className="text-red-700 hover:text-red-800 hover:cursor-pointer rounded-full focus:outline-none focus:ring-2 focus:ring-red-900"
      onClick={() => toggleGroup(group)}
      title="Remove User from Group"
      tabIndex={0}
    >
      <MinusCircleIcon className="h-8 w-8" aria-hidden="true" />
    </a>
  ) : (
    <a
      className="text-5minds-blue-800 hover:text-5minds-blue-900 hover:cursor-pointer rounded-full focus:outline-none focus:ring-2 focus:ring-5minds-blue-900"
      onClick={() => toggleGroup(group)}
      title="Add User to Group"
      tabIndex={0}
    >
      <PlusCircleIcon className="h-8 w-8" aria-hidden="true" />
    </a>
  );

  return (
    <div
      key={group.id}
      id="group_row_in_user_create"
      className="flex justify-between py-2 items-center position: relative"
    >
      <div className="flex flex-col w-2/3 text-sm leading-6">
        <div className="flex gap-3 items-baseline">
          <div className="font-medium text-gray-900">{group.name}</div>
        </div>
        <div className="text-gray-500 truncate">
          {group.description && group.description.length > 0 ? group.description : 'No description available'}
        </div>
      </div>
      <div className="flex justify-between py-2 items-center">{toggleButton}</div>
    </div>
  );
}
