import { useEffect, useState } from 'react';

import { FrontendUserClaim } from '../../../contracts';
import { EditOidcClaims } from './UserOidcClaimEditor';

type AddUserDetailsProps = {
  username: string;
  password: string;
  claims: Array<FrontendUserClaim>;
  save: (username: string, password: string, claims: Array<FrontendUserClaim>) => void;
};

export function AddUserAccount(props: AddUserDetailsProps): JSX.Element {
  const [username, setUsername] = useState(props.username);
  const [password, setPassword] = useState(props.password);
  const [claims, setClaims] = useState<Array<FrontendUserClaim>>(props.claims);

  useEffect(() => {
    props.save(username, password, claims);
  }, [username, password, claims]);

  return (
    <div className="p-4 border border-gray-200 border-t-transparent rounded-b-lg rounded-r-lg">
      <div className="flex flex-col gap-3">
        <EditOidcClaims
          username={username}
          setUsername={setUsername}
          password={password}
          setPassword={setPassword}
          claims={claims}
          setClaims={setClaims}
          placeholderActive={true}
          userCreation={true}
        />
      </div>
    </div>
  );
}
