import React from 'react';

type CardProps = {
  header: React.ReactNode;
  children: React.ReactNode;
};

export function Card(props: CardProps): JSX.Element {
  return (
    <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow-md border border-gray-300 min-w-screen-0.2">
      <div className="px-4 py-5 sm:px-6">{props.header}</div>
      <div className="px-4 py-4 sm:p-2">{props.children}</div>
    </div>
  );
}
