import React, { useEffect, useRef, useState } from 'react';

import { Claim, Scope, ScopeClaimChanges } from '@5minds/processcube_authority_sdk';

import { ErrorNotification, SuccessNotification, WithDefaultNavBar } from '../../../components';
import { getUrlParam, setUrlParam, sortByName } from '../../../infrastructure';
import { CreateScopeDialog } from './CreateScopeDialog';
import { DeleteScopeDialog } from './DeleteScopeDialog';
import { EditScopeDialog } from './EditScopeDialog';
import { SelectClaimsRenderer } from './SelectClaimsRenderer';
import { SelectScopesRenderer } from './SelectScopesRenderer';

type ScopesPageProps = {
  routerPrefix: string;
  logo: string;
  issuerUrl: string;
  scopes: Array<Scope>;
  claims: Array<Claim>;
};

function findSelectedScope(scopes: Array<Scope>): string | null {
  const scopeQuery = getUrlParam('scope');
  if (scopes.find((scope) => scope.name === scopeQuery)) {
    return scopeQuery;
  }
  return scopes[0]?.name ?? null;
}
const uniqueScopesFilter = (scope: Scope, index: number, array: Array<Scope>) =>
  index === array.findIndex((s) => s.id === scope.id);

export function ScopesPage(props: ScopesPageProps): JSX.Element {
  const [scopes, setScopes] = useState<Array<Scope>>(props.scopes.sort(sortByName));
  const [currentScopeName, setCurrentScopeName] = useState<string | null>(findSelectedScope(scopes));
  const [scopeChanges, setScopeChanges] = useState<ScopeClaimChanges>({});
  const [savingInProgress, setSavingInProgress] = useState<boolean>(false);

  const [shownDialog, setShownDialog] = useState<null | 'create' | 'update' | 'delete'>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const scope = scopes.find((scope) => scope.name === currentScopeName) ?? null;

  function createScope(name?: string, description?: string) {
    const body = JSON.stringify({ name, description });

    fetch(`${props.routerPrefix}/admin/scope/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    }).then(async (response) => {
      if (!response.ok) {
        const error = await response.json();

        setError(`${error.additionalInformation.name}: ${error.message}`);
        return;
      }

      const createdScope = await response.json();

      setScopes((scopes) => [...scopes, createdScope].sort(sortByName));
      setCurrentScopeName(createdScope.name);
      setShownDialog(null);
    });
  }

  function deleteScope(scopeName: string) {
    fetch(`${props.routerPrefix}/admin/scope/${scopeName}/delete`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(async (response) => {
      if (!response.ok) {
        const error = await response.json();

        setError(`${error.additionalInformation.name}: ${error.message}`);
        return;
      }

      const deletedScope = await response.json();
      const deletedScopeWasLastScope = scopes.length === 1 && scopes[0].name === deletedScope.name;

      setScopes(scopes.filter((scope) => scope.id !== deletedScope.id));
      setCurrentScopeName(deletedScopeWasLastScope ? null : scopes[0].name);
      setScopeChanges((scopeChanges) => {
        const newChanges = { ...scopeChanges };
        delete newChanges[deletedScope.name];
        return newChanges;
      });
      setShownDialog(null);
    });
  }

  function updateScopeDetails(scopeName: string, name?: string, description?: string) {
    const body = JSON.stringify({ name, description });
    fetch(`${props.routerPrefix}/admin/scope/${scopeName}/update`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    })
      .then(async (response) => {
        if (!response.ok) throw new Error(await response.text());
        return response.json();
      })
      .then((updatedScope: Scope) => {
        setScopes(scopes.map((scope) => (scope.id === updatedScope.id ? updatedScope : scope)).sort(sortByName));
        setSuccessMessage(`Scope ${updatedScope.name} updated successfully`);
        setShownDialog(null);
      })
      .catch((error) => {
        setError(error.message);
      });
  }

  function updateScopeClaims() {
    setSavingInProgress(true);
    const body = JSON.stringify({ changedScopeClaims: scopeChanges });

    fetch(`${props.routerPrefix}/admin/scope/update/claims`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    })
      .then(async (response) => {
        if (!response.ok) throw new Error(await response.text());
        return response.json();
      })
      .then((updatedScopes: Array<Scope>) => {
        setScopes([...updatedScopes, ...scopes].filter(uniqueScopesFilter).sort(sortByName));
        setSuccessMessage(`Scopes updated successfully`);
        setScopeChanges({});
        setSavingInProgress(false);
      })
      .catch((error) => {
        setSavingInProgress(false);
        setError(error.message);
      });
  }

  function addClaimToScope(claim: Claim) {
    if (currentScopeName === null) {
      return;
    }

    const changes = {
      addedClaimNames: scopeChanges[currentScopeName]?.addedClaimNames ?? [],
      removedClaimNames: scopeChanges[currentScopeName]?.removedClaimNames ?? [],
    } satisfies ScopeClaimChanges[string];

    if (scope && !scope.claims.find((c) => c.name === claim.name)) {
      changes.addedClaimNames.push(claim.name);
    }
    changes.removedClaimNames = changes.removedClaimNames.filter((name) => name !== claim.name);
    setScopeChanges({ ...scopeChanges, [currentScopeName]: changes });
  }

  function removeClaimFromScope(claim: Claim) {
    if (currentScopeName === null) {
      return;
    }

    const changes = {
      addedClaimNames: scopeChanges[currentScopeName]?.addedClaimNames ?? [],
      removedClaimNames: scopeChanges[currentScopeName]?.removedClaimNames ?? [],
    } satisfies ScopeClaimChanges[string];

    if (scope && scope.claims.find((c) => c.name === claim.name)) {
      changes.removedClaimNames.push(claim.name);
    }
    changes.addedClaimNames = changes.addedClaimNames.filter((name) => name !== claim.name);
    setScopeChanges({ ...scopeChanges, [currentScopeName]: changes });
  }

  useEffect(() => {
    setUrlParam('scope', currentScopeName ?? '');
  }, [currentScopeName]);

  useEffect(() => {
    const scopeChangeValues = Object.values(scopeChanges);
    const anyChangedScopes = scopeChangeValues.some(
      (scopeChange) => scopeChange.addedClaimNames.length > 0 || scopeChange.removedClaimNames.length > 0,
    );
  }, [scopeChanges]);

  let selectedClaimsNames = scope?.claims?.map((claim) => claim.name) ?? [];
  if (currentScopeName && scopeChanges[currentScopeName]) {
    selectedClaimsNames.push(...scopeChanges[currentScopeName].addedClaimNames);
    selectedClaimsNames = selectedClaimsNames.filter(
      (claim) => !scopeChanges[currentScopeName].removedClaimNames.includes(claim),
    );
  }

  return (
    <>
      <SuccessNotification message={successMessage} setMessage={setSuccessMessage} autoHide />
      <ErrorNotification message={error} setMessage={setError} />
      <CreateScopeDialog show={shownDialog === 'create'} hide={() => setShownDialog(null)} createScope={createScope} />
      {scope && (
        <>
          <EditScopeDialog
            show={shownDialog === 'update'}
            scope={scope}
            hide={() => setShownDialog(null)}
            updateDetails={updateScopeDetails}
          />
          <DeleteScopeDialog
            show={shownDialog === 'delete'}
            scope={scope}
            hide={() => setShownDialog(null)}
            deleteScope={deleteScope}
          />
        </>
      )}
      <WithDefaultNavBar issuerUrl={props.issuerUrl} logo={props.logo} routerPrefix={props.routerPrefix}>
        <div className="mx-auto max-w-6xl h-full border-x border-gray-200">
          <div className="flex h-full">
            {' '}
            <div className="flex flex-col w-1/2 h-full border-r border-gray-200">
              <SelectScopesRenderer
                currentScopeName={currentScopeName}
                scopes={scopes}
                scopeChanges={scopeChanges}
                savingInProgress={savingInProgress}
                updateScopeClaims={updateScopeClaims}
                setCurrentScope={(scope) => setCurrentScopeName(scope.name)}
                showCreateDialog={() => setShownDialog('create')}
                showDeleteDialog={() => setShownDialog('delete')}
                showUpdateDialog={() => setShownDialog('update')}
              />
            </div>
            {scope && (
              <div className="flex flex-col w-1/2 h-full">
                <SelectClaimsRenderer
                  allClaims={props.claims}
                  currentScope={scope}
                  selectedClaimsNames={selectedClaimsNames}
                  addClaimToScope={addClaimToScope}
                  removeClaimFromScope={removeClaimFromScope}
                />
              </div>
            )}
          </div>
        </div>
      </WithDefaultNavBar>
    </>
  );
}
