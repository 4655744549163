import cloneDeep from 'lodash/cloneDeep';
import { useCallback, useEffect, useState } from 'react';

import { UserClaim } from '@5minds/processcube_authority_sdk';

import { IdRenderer, Tooltip, claimHasChanges } from '../../../components';
import { FrontendUser } from '../../../contracts';
import { EditOidcClaims } from './UserOidcClaimEditor';

type EditUserAccountProps = {
  routerPrefix: string;
  user: FrontendUser;
  updateUserDetails: (username: string, password: string, changedOidcClaims: Array<UserClaim>) => void;
};

export function EditUserAccount(props: EditUserAccountProps): JSX.Element {
  const [username, setUsername] = useState(props.user.username);
  const [password, setPassword] = useState('');

  const [currentClaims, setCurrentClaims] = useState<Array<UserClaim>>(props.user.claims);

  const [updateDateTime, setUpdateDateTime] = useState(
    createUpdateDate(currentClaims.find((claim) => claim.name === 'updated_at')?.value),
  );

  function createUpdateDate(unixTimestamp: number) {
    if (!unixTimestamp) return 'The user has not yet been updated!';
    const updateDate = new Date(unixTimestamp);
    const updateDateTime = new Intl.DateTimeFormat('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    }).format(updateDate);
    return 'Last Update: ' + updateDateTime;
  }

  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [claimsToUpdate, setClaimsToUpdate] = useState<Array<UserClaim>>([]);
  const [updatedPassword, setUpdatedPassword] = useState('');
  const [showInvalideTooltip, setInvalideTooltip] = useState(false);
  const [intputValuesInvalide, setIntputValuesInvalide] = useState(false);
  const [invalideCalims, setInvalideCalims] = useState<Array<Record<string, any>>>([]);
  const [invalideMessage, setInvalideMessage] = useState('');

  function validateInput() {
    let inputValuesInvalide = false;
    const invlaideClaimsForMessage: Array<string> = [];
    const invlaideClaimsForState: Array<Record<string, any>> = [];

    const validationUserName = username;
    if (!validationUserName || validationUserName === '') {
      setIntputValuesInvalide(true);
      inputValuesInvalide = true;
      invlaideClaimsForState.push({ name: 'username', value: validationUserName });
      invlaideClaimsForMessage.push('username');
    }

    const emailAddress = claimsToUpdate.find((claim) => claim.name === 'email')?.value;
    if (emailAddress && emailAddress !== '') {
      const emailIsValide = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(emailAddress);
      if (!emailIsValide) {
        setIntputValuesInvalide(true);
        inputValuesInvalide = true;
        invlaideClaimsForState.push({ name: 'email', value: emailAddress });
        invlaideClaimsForMessage.push('email address');
      }
    }

    const phoneNumber = claimsToUpdate.find((claim) => claim.name === 'phone_number')?.value;
    if (phoneNumber && phoneNumber !== '') {
      const phoneNumberIsValide = /^[\+]?[(]?[0-9]{1,3}[)]?[-\s\.]?[0-9]{3,4}[-\s\.]?[0-9]{4,7}$/g.test(phoneNumber);
      if (!phoneNumberIsValide) {
        if (!intputValuesInvalide) setIntputValuesInvalide(true);
        inputValuesInvalide = true;
        invlaideClaimsForState.push({ name: 'phone_number', value: phoneNumber });
        invlaideClaimsForMessage.push('phone number');
      }
    }

    const birthdate = claimsToUpdate.find((claim) => claim.name === 'birthdate')?.value;
    if (birthdate && birthdate !== '') {
      const birthdateAsDate = new Date(birthdate);
      const birthdayAfterTheYear1900 = birthdateAsDate.getFullYear() > 1900;
      const birthdayNotInTheFuture = birthdateAsDate < new Date();
      const birthdateIsValide = birthdayAfterTheYear1900 && birthdayNotInTheFuture;
      if (!birthdateIsValide) {
        if (!intputValuesInvalide) setIntputValuesInvalide(true);
        inputValuesInvalide = true;
        invlaideClaimsForState.push({ name: 'birthdate', value: birthdate });
        invlaideClaimsForMessage.push('birthdate');
      }
    }

    if (inputValuesInvalide) {
      setInvalideCalims(invlaideClaimsForState);
      if (invlaideClaimsForMessage.length === 1) setInvalideMessage(`${invlaideClaimsForMessage[0]} is invalide`);
      else setInvalideMessage(`${invlaideClaimsForMessage.join(', ')} are invalide`);

      return false;
    }

    return true;
  }

  async function saveUserValues() {
    const inputValid = validateInput();
    if (!inputValid) return;

    setUpdatedPassword(password);
    setSaveButtonDisabled(true);
    props.updateUserDetails(username, password, cloneDeep(claimsToUpdate));
  }

  function getOldClaim(currentClaim: UserClaim): UserClaim | undefined {
    return props.user.claims.find((claim) => claim.name === currentClaim.name);
  }

  useEffect(() => {
    const changedClaims: UserClaim[] = currentClaims.filter(
      (currentClaim) => currentClaim.name !== 'updated_at' && claimHasChanges(currentClaim, getOldClaim(currentClaim)),
    );

    if (intputValuesInvalide) {
      const currentlyInvalideClaims: Array<Record<string, any>> = [];
      invalideCalims.forEach((invalideCalim) => {
        if (invalideCalim.name === 'username') {
          if (username === invalideCalim?.value) {
            currentlyInvalideClaims.push(invalideCalim);
          }
          return;
        }
        const currentClaim = changedClaims.find((changedClaim) => changedClaim.name === invalideCalim.name);
        if (currentClaim?.value === invalideCalim?.value) {
          currentlyInvalideClaims.push(invalideCalim);
        }
      });
      if (currentlyInvalideClaims.length === 0) {
        setIntputValuesInvalide(false);
        setInvalideCalims([]);
      } else setInvalideCalims(currentlyInvalideClaims);
    }

    const userNameWasChanged = props.user.username !== username;
    const passwordWasChanged = password !== updatedPassword;
    const claimsWereChanged = changedClaims.length !== 0;
    if (userNameWasChanged || passwordWasChanged || claimsWereChanged) setSaveButtonDisabled(false);
    else if (saveButtonDisabled === false) setSaveButtonDisabled(true);
    if (claimsWereChanged) setClaimsToUpdate(changedClaims);
    else if (claimsToUpdate.length !== 0) setClaimsToUpdate([]);
  }, [currentClaims, username, password]);

  useEffect(() => {
    setUpdateDateTime(createUpdateDate(props.user.claims.find((claim) => claim.name === 'updated_at')?.value));
  }, [props.user.claims.find((claim) => claim.name === 'updated_at')?.value]);

  return (
    <div className="p-4 border border-gray-200 rounded-b-lg rounded-r-lg">
      <div className="border-b border-gray-200 bg-white pb-2 mb-4 flex">
        <h2 className="text-2xl font-bold leading-6 text-gray-900 w-3/5">Edit {props.user.username}</h2>
        <h5 className="w-2/5 text-right text-gray-500">{updateDateTime}</h5>
      </div>
      <div className="flex flex-col gap-3">
        <h4>Account Information</h4>
        <IdRenderer title="AccountId" id={props.user.accountId} />
        <EditOidcClaims
          username={props.user.username}
          setUsername={setUsername}
          password={password}
          setPassword={setPassword}
          claims={currentClaims}
          setClaims={setCurrentClaims}
          invalideCalims={invalideCalims}
        />
        {intputValuesInvalide ? (
          <div className="ml-auto inline-flex justify-center">
            <Tooltip
              message={<p>{invalideMessage}</p>}
              show={showInvalideTooltip}
              setShow={setInvalideTooltip}
              customTooltipPositionY="-translate-y-14"
            >
              <button
                disabled={true}
                className="w-40 rounded-md border px-4 py-2 text-base font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm  focus:ring-5minds-blue-900 border-transparent text-white bg-red-900 hover:bg-red-950 sm:col-start-2"
              >
                Save
              </button>
            </Tooltip>
          </div>
        ) : (
          <button
            disabled={saveButtonDisabled}
            type="submit"
            className="w-40 ml-auto inline-flex justify-center rounded-md border px-4 py-2 text-base font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm  focus:ring-5minds-blue-900 border-transparent text-white bg-5minds-blue-800 disabled:bg-slate-300 hover:bg-5minds-blue-900 sm:col-start-2"
            onClick={() => saveUserValues()}
          >
            Save
          </button>
        )}
      </div>
    </div>
  );
}
