import React, { PropsWithChildren } from 'react';

import { setUrlParam } from '../infrastructure';

export type Tab = {
  name: string;
  href: string;
  current: boolean;
};

type WithTabsProps = {
  tabs: Tab[];
  setTabs: (tabs: Tab[]) => void;
};

type TabsProps = {
  tabs: Tab[];
  setTab: (tab: Tab) => void;
};

export function WithTabs({ tabs, setTabs, children }: PropsWithChildren<WithTabsProps>): JSX.Element {
  return (
    <div className="flex flex-col w-full h-full overflow-y-auto max-w-7xl mx-auto pt-4">
      <Tabs
        tabs={tabs}
        setTab={(tab) => {
          setUrlParam('tab', tab.href);
          setTabs(tabs.map((t) => ({ ...t, current: t.name === tab.name })));
        }}
      />
      {children}
    </div>
  );
}

export function Tabs({ tabs, setTab }: TabsProps): JSX.Element {
  return (
    <>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-5minds-blue-900 focus:ring-5minds-blue-900"
          defaultValue={tabs.find((tab) => tab.current)?.name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:flex">
        <nav
          className="w-fit h-full overflow-y-auto isolate flex divide-x divide-gray-200 rounded-lg border-gray-200 border rounded-b-none"
          aria-label="Tabs"
        >
          {tabs.map((tab, tabIdx) => (
            <div
              key={tab.name}
              className={`w-32 rounded-b-none group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 hover:cursor-pointer focus:z-10 
              ${tab.current ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700'} 
              ${tabIdx === 0 ? 'rounded-l-lg' : ''} ${tabIdx === tabs.length - 1 ? 'rounded-r-lg' : ''}`}
              aria-current={tab.current ? 'page' : undefined}
              onClick={() => setTab(tab)}
            >
              <span>{tab.name}</span>
              <span
                aria-hidden="true"
                className={`${tab.current ? 'bg-5minds-blue-900' : 'bg-transparent'} absolute inset-x-0 bottom-0 h-0.5`}
              />
            </div>
          ))}
        </nav>
      </div>
    </>
  );
}
